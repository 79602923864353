import React from "react";
import { Button, Typography } from "@mui/joy";
import StaffName from "../Staff/StaffName";
import SectionElementExpanded from "./SectionElementExpanded";
import SectionHeaderButton from "../Common/SectionHeaderButton";
function PresentationSections(
  {
    setSectionExpanded,
    sectionExpanded,
    sections,
    dialogue,
    page,
    selectedElement,
  },
) {
  function measureRangeCheck(mR, page) {
    return ((mR[0] === 1 && mR[1] === -1) || (mR[0] <= page && mR[1] >= page) ||
      (mR[0] <= page && mR[1] === -1));
  }
  const handleExpandSection = (sectionName) => {
    sectionExpanded.includes(sectionName)
      ? setSectionExpanded(
        sectionExpanded.filter((x) => x !== sectionName),
      )
      : setSectionExpanded([
        ...sectionExpanded,
        sectionName,
      ]);
  };
  function getElementsInSection(page, section) {
    return section.elements.map((element) => {
      return element.measureRanges.map((mR) => {
        if (measureRangeCheck(mR, page)) {
          return element.elementName;
        }
      });
    }).flat().filter((item) => item !== undefined);
  }

  return (
    <>
      {/* For each section render a section name and sectior row that can be expanded */}
      {sections.map((section, index) => {
        const elementsInSection = getElementsInSection(page, section);
        const isSectionExpanded = sectionExpanded.includes(section.sectionName);
        // const annotationScaleFactor = elementsInSection.length > 0
        //   ? elementsInSection.length
        //   : 1;
        const sLine = section.elements[0];
        const sLineHighlighted = sLine.elementName === selectedElement;
        // const characterDialogue = sLine
        //   ? dialogue.find((
        //     d,
        //   ) => (d.element === section.elements[0].elementName &&
        //     d.page === page)
        //   )?.message
        //   : "\u00A0";
        return (
          <div
            key={section.sectionName}
          >
            {selectedElement === null ||
              elementsInSection.includes(selectedElement)
              ? (
                <div>
                  <div className="staff-container">
                    <SectionHeaderButton
                      sectionName={section.sectionName}
                      onClickAction={() =>
                        handleExpandSection(section.sectionName)}
                      isSectionExpanded={isSectionExpanded}
                    />

                    {elementsInSection.length > 0
                      ? (
                        <>
                          <SectionElementExpanded
                            element={section.elements[0].elementName}
                            highlighted={sLineHighlighted}
                            rowStyle={"staff-row-presentation"}
                            dialogue={dialogue}
                            section={section}
                            page={page}
                            emptySpacer={false}
                          />
                        </>
                      )
                      : (
                        <div
                          style={{
                            display: "grid",
                            gridColumn: "2 / 5",
                            padding: "0.5rem 0",
                            borderRadius: "8px",
                            alignContent: "center",
                            justifyContent: "center",
                            backgroundColor: "rgb(236, 236, 236)",
                          }}
                        >
                          <Typography level="h4">
                            Section contains no elements
                          </Typography>
                        </div>
                      )}
                    {/* if sectionExpeded === true and selection has more than one element:  add a spacer */}
                  </div>
                  {isSectionExpanded
                    ? elementsInSection.map((element, index) => {
                      if (index > 0) {
                        return (
                          <SectionElementExpanded
                            key={element}
                            element={element}
                            highlighted={selectedElement === element}
                            rowStyle={"staff-row-presentation"}
                            dialogue={dialogue}
                            section={section}
                            page={page}
                            emptySpacer={index ===
                              elementsInSection.length - 1}
                          />
                        );
                      }
                    })
                    : (null)}
                </div>
              )
              : (null)}
          </div>
        );
      })}
    </>
  );
}

export default PresentationSections;
