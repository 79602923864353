import React, { useState } from "react";
import { Alert, Box, Button, Chip, Input, Table, Typography } from "@mui/joy";
import { InputLabel } from "@mui/material";

function AddSectionModal(
  { show, handleClose, staffElements, setStaffElements },
) {
  const emptySection = {
    sectionName: "",
    elements: [],
  };

  const [newSection, setNewSection] = useState(emptySection);
  const { elements, sections } = staffElements;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setSections = (payload) => {
    setStaffElements({
      elements: staffElements.elements,
      sections: [
        ...staffElements.sections,
        payload,
      ],
    });
  };

  const addSectionElement = (elemName) => {
    setNewSection({
      ...newSection,
      elements: [
        ...newSection.elements,
        {
          elementName: elemName,
          measureRanges: [
            [1, -1],
          ],
        },
      ],
    });
  };

  const removeSectionElement = (elemName) => {
    setNewSection({
      ...newSection,
      elements: [
        ...newSection.elements.filter((element) =>
          element.elementName !== elemName
        ),
      ],
    });
  };
  const handleClear = () => {
    setNewSection(emptySection);
    setIsSubmitted(false);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    if (newSection.sectionName !== "") {
      setSections(newSection);
      handleClear();
      // handleClose();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        {(isSubmitted && newSection.sectionName === "")
          ? (
            <Alert sx={{ justifyContent: "center" }} color="danger">
              Section Name required
            </Alert>
          )
          : ("")}
        {sections.map((section) => section.sectionName).includes(
            newSection.sectionName,
          )
          ? (
            <Alert sx={{ justifyContent: "center" }} color="danger">
              Section names must be unique
            </Alert>
          )
          : ("")}
        <InputLabel>Section Name</InputLabel>
        <Input
          // size="lg"
          // label={"Section Name"}
          value={newSection.sectionName}
          onChange={(e) =>
            setNewSection(
              {
                ...newSection,
                sectionName: e.target.value.toUpperCase(),
              },
            )}
        />
      </Box>
      <Box
        sx={{ display: "flex", mb: 2 }}
      >
        <InputLabel className="h4">Elements</InputLabel>
        <br />
        <Box
          sx={{
            boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
            mr: 1,
            borderRadius: "8px",
            height: "50vh",
            maxHeight: "250px",
            overflowX: "auto",
          }}
        >
          <Table
            hoverRow
            stickyHeader
            borderAxis="x"
            color={(isSubmitted && newSection.elements.length === 0)
              ? "danger"
              : ""}
            sx={{
              "--TableCell-paddingX": "16px",
            }}
          >
            <thead>
              <tr>
                <th style={{ display: "flex", alignItems: "baseline" }}>
                  Available Elements
                  <Chip sx={{ ml: 2 }}>
                    Click to add element
                  </Chip>
                </th>
              </tr>
            </thead>
            <tbody>
              {elements.filter((element) =>
                !newSection.elements.map((elem) => elem.elementName).includes(
                  element,
                )
              ).map((element) => {
                return (
                  <tr
                    key={element}
                    value={element}
                    onClick={() => addSectionElement(element)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{element}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>
        <Box
          sx={{
            boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
            ml: 1,
            borderRadius: "8px",
            height: "50vh",
            maxHeight: "250px",
            overflow: "auto",
          }}
        >
          <Table
            borderAxis="x"
            hoverRow
            stickyHeader
            sx={{
              "--TableCell-paddingX": "16px",
            }}
          >
            <thead>
              <tr>
                <th style={{ display: "flex", alignItems: "baseline" }}>
                  Elements in New Section
                  <Chip sx={{ ml: 2 }}>
                    Click to remove element
                  </Chip>
                </th>
              </tr>
            </thead>
            <tbody>
              {newSection.elements.map((element) => {
                return (
                  <tr
                    key={element.elementName}
                    onClick={() => removeSectionElement(element.elementName)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{element.elementName}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>
        {
          /*
          <FormGroup>
            <InputLabel className="h4">{"Measures "}</InputLabel>
            <br />
            <div className="d-flex">
              <div className="d-inline-flex w-75">
                <FormControl
                  type="number"
                  disabled={newSection.measureRangeEntirePlay}
                  value={newSection.measureRange[0]}
                  onChange={(e) => setLowerMeasureRange(e)}
                />
                <InputLabel className="h4 mx-2">{" to "}</InputLabel>
                <FormControl
                  type="number"
                  disabled={newSection.measureRangeEntirePlay}
                  value={newSection.measureRange[1]}
                  onChange={(e) => setUpperMeasureRange(e)}
                />
              </div>
              <div className="d-inline-flex align-content-center align-self-end w-25">
                <Form.Check
                  className="mx-2"
                  size="lg"
                  type="checkbox"
                  id={"measureRangeEntirePlay"}
                  checked={!!newSection.measureRangeEntirePlay}
                  onChange={() =>
                    setNewSection({
                      ...newSection,
                      measureRangeEntirePlay: !newSection
                        .measureRangeEntirePlay,
                    })}
                />
                <InputLabel className="h5">Entire Play?</InputLabel>
              </div>
            </div>
          </FormGroup>
          */
        }
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ mr: 3 }}
          color="danger"
          onClick={() => handleClear()}
        >
          Clear
        </Button>
        <Button
          color="primary"
          onClick={() => handleSubmit()}
        >
          Create Section
        </Button>
      </Box>
    </Box>
  );
}

export default AddSectionModal;
