import React, { useEffect, useState } from "react";
// import { Button, Form, FormControl } from "react-bootstrap";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Input,
  Table,
  Typography,
} from "@mui/joy";
import { InputLabel } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

const emptyElement = {
  elementName: "",
  measureRanges: [
    [1, -1],
  ],
};

function ModalElementMeasureRange(
  {
    editElement,
    setEditElement,
    setEditSection,
    editSection,
    measure,
    elementsInSectionMeasure,
    isEdited,
    setIsEdited,
    handleSubmit,
    handleClear,
    setStaffElements,
    staffElements,
    elementsInSection,
  },
) {
  const elementsInEditSectionMeasure = elementsInSectionMeasure.map((e) =>
    e.elementName
  );
  const isElementInSectionMeasure = elementsInEditSectionMeasure.includes(
    editElement.elementName,
  );

  const isElementInSectionArray = editSection.elements.map((element) =>
    element.elementName
  );
  const isElementInSection = editSection.elements.map((element) =>
    element.elementName
  ).includes(
    editElement.elementName,
  );

  // console.log("elementsInEditSection " + isElementInSectionArray.toString());
  // console.log(
  //   "elementsInEditSectionMeasure " + elementsInEditSectionMeasure.toString(),
  // );

  function getBounds(element, measure) {
    let upper = -1;
    let lower = 1;

    element.measureRanges.map((mR) => {
      if (mR[1] < measure && mR[1] !== -1) {
        lower = mR[1];
      }
      if (mR[0] > measure && mR[0] < upper) {
        upper = mR[0];
      }
    });
    return [lower, upper];
  }

  function measureRangeCheck(mR, measure) {
    return ((mR[0] === 1 && mR[1] === -1) ||
      (mR[0] <= measure && mR[1] >= measure) ||
      (mR[0] <= measure && mR[1] === -1));
  }

  const getCurrentMeasureRange = (element, measure) => {
    return element.measureRanges.find((mR) => {
      if (measureRangeCheck(mR, measure)) {
        return mR;
      }
    });
  };

  const [bounds, setBounds] = useState(getBounds(editElement, measure));

  useEffect(() => {
    if (isElementInSectionMeasure) {
      setBounds(getCurrentMeasureRange(editElement, measure));
    } else {
      setBounds(getBounds(editElement, measure));
    }
  }, [editElement, measure]);

  const onHandleEntirePlay = () => {
    if (bounds[1] === -1) {
      setBounds([bounds[0], measure]);
    } else {
      setBounds([bounds[0], -1]);
    }
  };

  function makeRangesContiguous(measureRanges) {
    if (measureRanges.length === 0) {
      return [];
    }

    // Sort the ranges based on the starting value
    measureRanges.sort((a, b) => a[0] - b[0]);

    const result = [];
    let currentRange = measureRanges[0];

    for (let i = 1; i < measureRanges.length; i++) {
      const nextRange = measureRanges[i];

      if (currentRange[1] === -1) {
        // Treat -1 as Infinity
        currentRange[1] = Infinity;
      }

      if (currentRange[1] + 1 >= nextRange[0]) {
        // Ranges are contiguous or overlapping, merge them
        currentRange[1] = nextRange[1] === -1
          ? -1
          : Math.max(currentRange[1], nextRange[1]);
      } else {
        // Ranges are not contiguous, push the current range to the result
        result.push(currentRange);
        currentRange = nextRange;
      }
    }

    // Push the last range to the result
    result.push(currentRange);

    return result.map((
      range,
    ) => (range[1] === Infinity ? [range[0], -1] : range)); // Convert back to -1 if necessary
  }

  // function makeRangesContiguous(measureRanges) {
  //   // Sort the ranges based on the starting value
  //   measureRanges.sort((a, b) => a[0] - b[0]);
  //
  //   const result = [];
  //   let currentRange = measureRanges[0];
  //
  //   for (let i = 1; i < measureRanges.length; i++) {
  //     const nextRange = measureRanges[i];
  //
  //     if (currentRange[1] + 1 >= nextRange[0]) {
  //       // Ranges are contiguous or overlapping, merge them
  //       currentRange[1] = nextRange[1] === -1
  //         ? -1
  //         : Math.max(currentRange[1], nextRange[1]);
  //     } else {
  //       // Ranges are not contiguous, push the current range to the result
  //       result.push(currentRange);
  //       currentRange = nextRange;
  //     }
  //   }
  //
  //   // Push the last range to the result
  //   result.push(currentRange);
  //
  //   return result;
  // }

  function insertAndCombineRanges(measureRanges, newRange) {
    const result = [];
    let inserted = false;

    for (const range of measureRanges) {
      if (newRange[1] < range[0] - 1) {
        // New range is completely to the left of the current range
        if (!inserted) {
          result.push(newRange);
          inserted = true;
        }
        result.push(range);
      } else if (newRange[0] > range[1] + 1) {
        // New range is completely to the right of the current range
        result.push(range);
      } else {
        // There's an overlap; merge the ranges
        newRange[0] = Math.min(newRange[0], range[0]);
        newRange[1] = newRange[1] === -1 ? -1 : Math.max(newRange[1], range[1]);
      }
    }

    if (!inserted) {
      result.push(newRange);
    }

    return makeRangesContiguous(result);
  }

  // function insertAndCombineRanges(measureRanges, newRange) {
  //   const result = [];
  //   let inserted = false;
  //
  //   // If measureRange: [1, -1] just replace range
  //   for (const range of measureRanges) {
  //     if (newRange[1] < range[0] - 1) {
  //       // New range is completely to the left of the current range
  //       if (!inserted) {
  //         result.push(newRange);
  //         inserted = true;
  //       }
  //       result.push(range);
  //     } else if (newRange[0] > range[1] + 1) {
  //       // New range is completely to the right of the current range
  //       result.push(range);
  //     } else {
  //       // There's an overlap; merge the ranges
  //       newRange[0] = Math.min(newRange[0], range[0]);
  //       newRange[1] = Math.max(newRange[1], range[1]);
  //     }
  //   }
  //   if (!inserted) {
  //     result.push(newRange);
  //   }
  //   return result;
  // }

  const handleAddElement = () => {
    let newElements = [];
    if (isElementInSection) {
      // if element aready exists in section
      newElements = editSection.elements.map((element) =>
        element.elementName === editElement.elementName
          ? {
            ...editElement,
            measureRanges: insertAndCombineRanges(
              editElement.measureRanges,
              bounds,
            ),
          }
          : element
      );
    } else {
      //if element does not exist in section
      newElements = [...editSection.elements, {
        ...editElement,
        measureRanges: [bounds],
      }];
    }

    setEditSection({
      ...editSection,
      elements: newElements,
    });
    setIsEdited(true);

    // Causese a rerender, maybe use a section name as useRef?
    // setStaffElements({
    //   elements: staffElements.elements,
    //   sections: staffElements.sections.map((section) =>
    //     (section.sectionName === editSection.sectionName)
    //       ? {
    //         ...editSection,
    //         elements: newElements,
    //       }
    //       : section
    //   ),
    // });
    // setEditElement(emptyElement);
  };
  const handleRemoveElement = () => {
    setEditSection({
      ...editSection,
      elements: editSection.elements.filter((element) =>
        element.elementName !== editElement.elementName
      ),
    });

    setIsEdited(true);
  };
  const handleEditElement = () => {
    // Get measureRange that contains current measure for editElement
    // and bounds
    const currMeasureRange = getCurrentMeasureRange(editElement, measure);
    const nextMeasureRange = bounds;
    // Replace that measureRange with current bounds in editElements
    // measureRange array
    const index = editElement.measureRanges.indexOf(currMeasureRange);
    let result = editElement.measureRanges.with(index, nextMeasureRange);
    // Run smoothing / one off array organizer
    result = makeRangesContiguous(result);

    // apply changes to staffElements
    //
    // setEditSection(staffElements.sections.find((section) section.sectionName === editSection.section))
    setEditSection({
      ...editSection,
      elements: editSection.elements.map((element) =>
        element.elementName === editElement.elementName
          ? {
            ...editElement,
            measureRanges: result,
          }
          : element
      ),
    });
    setIsEdited(true);

    // setEditSection({
    //   ...editSection,
    //   elements: editSection.elements.map((element) =>
    //     element.elementName === editElement.elementName
    //       ? {
    //         ...editElement,
    //         measureRanges: result,
    //       }
    //       : element
    //   ),
    // });
    // setIsEdited(true);

    // setEditElement(emptyElement);
  };

  const handleLowerBounds = (e) => {
    if (parseInt(e.target.value) >= 1) {
      setBounds(() => [parseInt(e.target.value), bounds[1]]);
    }
    if (parseInt(e.target.value) >= bounds[1]) {
      setBounds(() => [parseInt(e.target.value), parseInt(e.target.value)]);
    }
  };

  const handleUpperBounds = (e) => {
    if (bounds[0] <= parseInt(e.target.value) >= 1) {
      setBounds(() => [bounds[0], parseInt(e.target.value)]);
    }
  };

  return (
    <>
      <Box
        sx={{
          // border: "1px solid grey",
          // display: "flex",
          borderRadius: "8px",
          p: 1,
          my: 1,
          boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
        }}
      >
        {isEdited
          ? (
            <Typography
              variant="soft"
              color="primary"
              startDecorator={<InfoOutlined />}
              fontSize="sm"
              sx={{ "--Typography-gap": "0.5rem", borderRadius: "8px" }}
            >
              Click Save Changes to confim edits
            </Typography>
          )
          : (null)}
        {isElementInSectionMeasure
          ? (
            <>
              <Box
                sx={{
                  // border: "1px solid grey",
                  display: "flex",
                  borderRadius: "8px",
                  p: 1,
                  my: 1,
                  // boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    display: "block",
                    justifyContent: "center",
                    width: "fit-context",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "inline-flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>
                      {"Edit " + ((editElement.elementName === "")
                        ? ("element")
                        : editElement.elementName) +
                        " for which measures?"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "inline-flex",
                      justifyContent: "center",
                    }}
                  >
                    <Input
                      sx={{ maxWidth: "10ch" }}
                      type="number"
                      disabled={editElement.elementName === ""}
                      value={bounds[0]}
                      onChange={(e) => handleLowerBounds(e)}
                    />
                    <Typography
                      level="body-lg"
                      sx={{ alignSelf: "center", mx: 1 }}
                    >
                      {" to "}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Input
                        sx={{ maxWidth: "10ch" }}
                        type="number"
                        disabled={editElement.elementName === "" ||
                          bounds[1] === -1}
                        value={bounds[1]}
                        onChange={(e) => handleUpperBounds(e)}
                      />
                      <Checkbox
                        sx={{ alignSelf: "center", ml: 1 }}
                        label={"Entire Play?"}
                        disabled={editElement.elementName === ""}
                        checked={bounds[1] === -1}
                        onChange={() => onHandleEntirePlay()}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "end",
                    alignSelf: "center",
                    // width: "25%",
                  }}
                >
                  <Button
                    // sx={{ : "flex-end" }}
                    disabled={editElement.elementName === ""}
                    onClick={() => handleEditElement()}
                  >
                    {`Edit ${editElement.elementName}`}
                  </Button>
                  <Typography sx={{ mx: 2, alignSelf: "center" }}>
                    OR
                  </Typography>
                  <Button
                    color="danger"
                    disabled={editElement.elementName === ""}
                    onClick={() => handleRemoveElement()}
                  >
                    {`Remove ${editElement.elementName}?`}
                  </Button>
                </Box>
              </Box>
            </>
          )
          : (
            <Box
              sx={{
                // border: "1px solid grey",
                display: "flex",
                borderRadius: "8px",
                p: 1,
                my: 1,
                // boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
              }}
            >
              <Box
                sx={{
                  display: "block",
                  justifyContent: "center",
                  width: "fit-context",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "inline-flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography>
                    {"Add " + ((editElement.elementName === "")
                      ? ("element")
                      : editElement.elementName) +
                      " for which measures?"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "inline-flex",
                    justifyContent: "center",
                  }}
                >
                  <Input
                    sx={{ maxWidth: "10ch" }}
                    type="number"
                    disabled={editElement.elementName === ""}
                    value={bounds[0]}
                    onChange={(e) => handleLowerBounds(e)}
                  />
                  <Typography
                    level="body-lg"
                    sx={{ alignSelf: "center", mx: 1 }}
                  >
                    {" to "}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Input
                      sx={{ maxWidth: "10ch" }}
                      type="number"
                      disabled={editElement.elementName === "" ||
                        bounds[1] === -1}
                      value={bounds[1]}
                      onChange={(e) => handleUpperBounds(e)}
                    />
                    <Checkbox
                      sx={{ alignSelf: "center", ml: 1 }}
                      label={"Entire Play?"}
                      disabled={editElement.elementName === ""}
                      checked={bounds[1] === -1}
                      onChange={() => onHandleEntirePlay()}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "end",
                  alignSelf: "center",
                  width: "25%",
                }}
              >
                <Button
                  // sx={{ justifySelf: "flex-end" }}
                  disabled={editElement.elementName === ""}
                  onClick={() => handleAddElement()}
                >
                  {`Add ${editElement.elementName}`}
                </Button>
              </Box>
            </Box>
          )}
        <Box
          sx={{ display: "flex", justifyContent: "space-evenly", pb: 1}}
        >
          <Button
            color="danger"
            onClick={() => handleClear()}
          >
            Clear
          </Button>
          <Button
            color="primary"
            disabled={editSection.sectionName === ""}
            onClick={() => handleSubmit()}
          >
            Save Changes to {editSection.sectionName}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ModalElementMeasureRange;
