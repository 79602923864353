import React from "react";
import StaffName from "../Staff/StaffName";
import RowSpacer from "../Common/RowSpacer";
import { Typography } from "@mui/joy";
import PresentationDialogue from "./PresentationDialogue";

function PresentationElements(
  {
    sectionElementsToExclude,
    elements,
    dialogue,
    page,
    selectedElement,
  },
) {
  const elementsNotInASection = elements.filter((elem) =>
    !sectionElementsToExclude.includes(elem)
  );
  // console.log(elementsNotInASection);
  return (
    elementsNotInASection.length > 0 &&
      (elementsNotInASection.includes(selectedElement) ||
        selectedElement === null)
      ? (
        <>
          <div
            style={{
              width: "113ch",
              textAlign: "center",
              fontSize: "inherit",
              fontFamily: "Courier New",
              marginLeft: "12ch",
            }}
          >
            <Typography level="h3" sx={{ letterSpacing: "2px" }}>
              Off Stage
            </Typography>
          </div>
          {elementsNotInASection.map((element, index) => {
            return (
              <div key={element} className="staff-container">
                <StaffName
                  elementName={element}
                  menuOptions={[]}
                  highlighted={false}
                />
                <PresentationDialogue
                  highlighted={false}
                  rowStyle={"staff-row-presentation"}
                  element={element}
                  dialogue={dialogue}
                  page={page}
                />
                <RowSpacer
                  emptySpacer={index === elementsNotInASection.length - 1}
                />
              </div>
            );
          })}
        </>
      )
      : (null)
  );
}

export default PresentationElements;
