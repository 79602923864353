import React, {useEffect, useState} from "react";
import { Box, Chip } from "@mui/joy";
import { useSelector } from "react-redux";
function LastSaved(props) {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const document = useSelector((state) => state.document);
  const created_at = document.created_at;
  const lastUpdate = document.updated_at === null
    ? time 
    : Date.parse(document.updated_at);
  const timeSinceSave = Math.abs(((time - lastUpdate) / 60000).toFixed(0));
  const timeSinceMessageFunc = () => {
    if (created_at === null) {
      return "Not saved";
    } else if (timeSinceSave === 0) {
      return `Saved`;
    } else if (timeSinceSave < 60) {
      return `Saved ${timeSinceSave} minutes ago`;
    } else {
      return "More than an hour";
    }
  };
  const timeSinceMessage = timeSinceMessageFunc();
  // console.log(created_at);
  // const timeSinceMessage = ((created_at === null) ? 'Not saved' : ((timeSinceSave < 60) ? `Saved ${timeSinceSave} minutes ago` : "More than an hour"));
  // console.log(timeSinceMessage);

  // console.log(lastUpdate);
  return (
    <>
      <Box
        sx={{ maxHeight: "2rem" }}
      >
        <Chip
          color={timeSinceSave < 15 ? "primary" : "danger"}
          sx={{ px: 2 }}
        >
          {timeSinceMessage}
        </Chip>
      </Box>
    </>
  );
}

export default LastSaved;
