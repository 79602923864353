import React, { useEffect, useRef, useState } from "react";
import Elements from "./Elements";
import Sections from "./Sections.jsx";
import { Typography } from "@mui/joy";
function StaffElements(
  {
    elements,
    sections,
    dialogue,
    page,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
    documentId,
    staffElements,
    setStaffElements
  },
) {
  const [sectionExpanded, setSectionExpanded] = useState([]);
  const staffElementsRef = useRef(null);
  const [staffHeight, setStaffHeight] = useState("100vh");
  function measureRangeCheck(mR, page) {
    return ((mR[0] === 1 && mR[1] === -1) || (mR[0] <= page && mR[1] >= page) ||
      (mR[0] <= page && mR[1] === -1));
  }
  useEffect(() => {
    if (staffElementsRef.current !== null) {
      // console.log("clientHeight " + staffElementsRef.current.clientHeight);
      setStaffHeight(`calc(${staffElementsRef.current.clientHeight}px + 5rem)`);
    }
  }, [staffElementsRef.current, sectionExpanded]);

  useEffect(() => {
    setSectionExpanded([]);
  }, [documentId]);
  function elementsInSection(page, section) {
    return section.elements.map((element) => {
      return element.measureRanges.map((mR) => {
        if (measureRangeCheck(mR, page)) {
          return element.elementName;
        }
      });
    }).flat().filter((item) => item !== undefined);
  }

  const sectionElementsToExclude = sections.map((section) => {
    return elementsInSection(page, section);
  }).flat();
  return (
    <div className="editor-container">
      <div
        className="staff-ruler"
        style={{
          height: staffHeight,
        }}
      />
      <div className="canvas-container">
        {(elements.length === 0 && sections.length === 0) && (
          <div
            style={{
              padding: "1rem 0 1rem 0",
              width: "113ch",
              textAlign: "center",
              fontSize: "inherit",
              fontFamily: "Courier New",
              marginLeft: "12ch",
            }}
          >
            <Typography level="h2" sx={{ letterSpacing: "1px", color: "rgba(0,0,0,0.6)" }}>
              Add elements or Open file
            </Typography>
          </div>
        )}
        <div
          style={{
            fontSize: "inherit",
            position: "relative",
            left: "120ch",
            top: "0",
            width: "fit-content",
          }}
        >
          <p>
            {page}
          </p>
        </div>
        <div
          ref={staffElementsRef}
        >
          <Sections
            staffElements={staffElements}
            setStaffElements={setStaffElements}
            setSectionExpanded={setSectionExpanded}
            sectionExpanded={sectionExpanded}
            sections={sections}
            dialogue={dialogue}
            setDialogue={setDialogue}
            characterFocus={characterFocus}
            setCharacterFocus={setCharacterFocus}
            page={page}
            // Empty Measure Show Section Modals
            handleShowSectionModal={handleShowSectionModal}
          />
          <Elements
            sectionElementsToExclude={sectionElementsToExclude}
            elements={elements}
            dialogue={dialogue}
            page={page}
            setDialogue={setDialogue}
            characterFocus={characterFocus}
            setCharacterFocus={setCharacterFocus}
          />
        </div>
      </div>
    </div>
  );
}

export default StaffElements;
