import React, { useCallback, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SwsengIcon from "../Layout/SwsengIcon";
import Copyright from "../Common/Copyright";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { setUserData } from "../User/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;
export default function ConfirmResetPassword() {
  const navigate = useNavigate();
  let { token } = useParams();

  const [isTokenValid, setIsTokenValid] = useState(false);
  async function validateToken() {
    setIsTokenValid("loading");
    const body = JSON.stringify({ token: token });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Swseng-Api-Key": apiKey,
      },
    };
    try {
      const response = await axios.post(
        apiEndpoint + `/password_reset/validate_token/`,
        body,
        config,
      );
      setIsTokenValid("success");
    } catch (error) {
      setIsTokenValid("failed");
    }
  }
  useEffect(() => {
    validateToken();
  }, []);

  const renderContent = React.useCallback(() => {
    const [passwordError, setPasswordError] = useState({
      message: "",
      isValid: true,
    });
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const passwordsMatch = password === passwordConfirm;
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [isResetSuccessful, setIsResetSuccessful] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleSubmit = (event) => {
      setIsLoading(true);
      event.preventDefault();
      async function resetPassword() {
        const body = JSON.stringify({ token: token, password: password });
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Swseng-Api-Key": apiKey,
          },
        };
        try {
          const response = await axios.post(
            apiEndpoint + `/password_reset/confirm/`,
            body,
            config,
          );
          setIsLoading(false);
          setIsResetSuccessful(true);
          setTimeout(() => {
            navigate("/signin");
          }, 2000);
        } catch (error) {
          setIsLoading(false);
          if (error.response.data.password) {
            setPasswordError({
              message: error.response.data.password,
              isValid: false,
            });
          }
        }
      }
      resetPassword();
    };

    switch (isTokenValid) {
      case "loading":
        return (
          <>
            <Typography
              mb={3}
              component="p"
              variant="p"
              textAlign={"center"}
            >
              Please enter a new password
            </Typography>
          </>
        );

      case "success":
        return (
          <>
            {isResetSuccessful
              ? (
                <Typography
                  mb={3}
                  component="p"
                  variant="p"
                  textAlign={"center"}
                >
                  Password reset successful, you will now be redirected to login
                  with your new password.
                </Typography>
              )
              : (
                <>
                  <Typography
                    mb={3}
                    component="p"
                    variant="p"
                    textAlign={"center"}
                  >
                    Please enter a new password
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    {passwordError.isValid
                      ? (null)
                      : (
                        <Alert sx={{ alignItems: "center" }} severity="info">
                          {passwordError.message.map((error) => (
                            <Typography
                              key={error}
                              component="p"
                              variant="p"
                            >
                              {error}
                            </Typography>
                          ))}
                        </Alert>
                      )}
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword
                                ? <VisibilityOff />
                                : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        error={!passwordsMatch}
                        type={"password"}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        label="Confirm Password"
                      />
                    </FormControl>
                    <Button
                      disabled={!passwordsMatch || password === "" || isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {isLoading ? <CircularProgress /> : ("Submit")}
                    </Button>
                  </Box>
                </>
              )}
          </>
        );

      case "failed":
        return (
          <>
            <Typography
              mb={3}
              component="h1"
              variant="h4"
              textAlign={"center"}
            >
              Link invalid
            </Typography>
            <Typography
              mb={3}
              component="p"
              variant="p"
              textAlign={"center"}
            >
              The password reset link is no longer valid, try resetting your
              password again
            </Typography>
          </>
        );

      default:
        return (
          <>
            <Typography
              mb={3}
              component="h1"
              variant="h4"
              textAlign={"center"}
            >
              Ooops something went wrong
            </Typography>
            <Typography
              mb={3}
              component="p"
              variant="p"
              textAlign={"center"}
            >
              Try pasting the link again or reseting your password
            </Typography>
          </>
        );
    }
  }, [isTokenValid]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SwsengIcon />
        </Box>
        <Box
          // component="form"
          // onSubmit={handleSubmit}
          noValidate
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography mb={3} component="h1" variant="h4" textAlign={"center"}>
            Password Reset
          </Typography>
          {renderContent()}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
