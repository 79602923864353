import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SwsengIcon from "../Layout/SwsengIcon";
import Copyright from "../Common/Copyright";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUserData } from "../User/userSlice";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;

export default function ConfirmEmail() {
  let { uid, token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activateData, setActivateData] = useState({
    message: "Loading ...",
    state: "loading",
  });

  async function fetchActivateEmail() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Swseng-Api-Key": apiKey,
      },
    };
    try {
      const response = await axios.get(
        apiEndpoint + `/user/activate/${uid}/${token}`,
        config,
      );

      setActivateData({ message: response.data.message, state: "success" });
      dispatch(
        setUserData({
          user: response.data.user,
          token: response.data.token,
        }),
      );
    } catch (error) {
      if (error.response) {
        setActivateData({
          message: error.response.data.message,
          state: "failed",
        });
      }
    }
  }
  useEffect(() => {
   fetchActivateEmail();
  }, []);
  if (activateData.state === "success") {
    setTimeout(
      navigate("/"),
      5000,
    );
  }
  const ActivateEmail = () => {
    return (
      <>
        <Typography mb={3} component="h1" variant="h5" textAlign={"center"}>
          {activateData.message}
        </Typography>
        {activateData.state === "success"
          ? (
            <Typography component="p" variant="p" textAlign={"center"}>
              {"This page will automatically redirect you to the home page. Use this "}
              <Link component={RouterLink} to={"/"}>
                link
              </Link>
              {" if you're stuck."}
            </Typography>
          )
          : (activateData.state === "failed"
            ? (
              <Typography component="p" variant="p" textAlign={"center"}>
                {"Hmmmm, you've either already confirmed your email (try logging in) or haven't registered with us yet."}
              </Typography>
            )
            : (
              <Typography component="p" variant="p" textAlign={"center"}>
                {"This should only take a second."}
              </Typography>
            ))}
      </>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SwsengIcon />
        </Box>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ActivateEmail />
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
