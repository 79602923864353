import React, { useEffect, useState } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/material/Box";
import { Button, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddElementModal from "./AddElementModal";
import EditElementModal from "./EditElementModal";

import { createTheme, ThemeProvider } from "@mui/material";

function ElementModal(props) {
  const   { show, handleClose, handleShow, staffElements, setStaffElements, setDialogue, dialogue } = props;
  const [tabValue, setTabValue] = React.useState(show.tabIndex);
  const handleTabChange = (event, newValue) => {
    handleShow(newValue);
  };

  const buttonTheme = createTheme({
    palette: {
      ochre: {
        main: "#c8c8c8",
        light: "#f8f8f8",
        dark: "#979798",
        contrastText: "#000",
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // "&:hover, &.Mui-focusVisible": {
            //   backgroundColor: "#d1eaff",
            //   boxShadow: "none",
            // },
            backgroundColor: "#fff",
            padding: "0 1rem 0px 1rem",
            borderRadius: "14px",
            border: "1px solid grey",
            fontFamily: "Google Sans, sans-serif",
            letterSpacing: "1px",
            fontSize: "1.2rem",
            boxShadow: "none",
            textTransform: "none",
            margin: "0 0 0 0",
            color: "#000",
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            paddingLeft: "24px",
            paddingRight: "48px",
          },
        },
      },
    },
  });

  function TabOptions() {
    return (
      <TabContext value={show.tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
          >
            <Typography
              variant="h4"
              sx={{ textAlign: "center", paddingRight: 2 }}
            >
              Element
            </Typography>
            <Tab label="Add Element" value="1" />
            <Tab label="Edit Element" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
            <AddElementModal
              staffElements={staffElements}
              setStaffElements={setStaffElements}
            />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
            <EditElementModal
              staffElements={staffElements}
              setStaffElements={setStaffElements}
              dialogue={dialogue}
              setDialogue={setDialogue}
            />
          </Box>
        </TabPanel>
      </TabContext>
    );
  }

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={show.open}
        onClose={() => handleClose()}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "75%",
            height: "auto",
            position: "absolute",
            top: "10vh",
            // minWidth: "50%",
            maxWidth: "1080px",
            background: "#f8f8f8",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <TabOptions />
          <ThemeProvider theme={buttonTheme}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                // disabled={fileSelection === ""}
                onClick={() => {
                  // handleLoadFile(fileSelection);
                  // setFileSelection("");
                  handleClose();
                }}
                // type="submit"
                // variant="primary"
              >
                Done
              </Button>
            </Box>
          </ThemeProvider>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
export default ElementModal;
