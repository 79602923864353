import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";

  function SwsengIcon() {
    return (
      <img src={SwsengLogo} style={{ height: "64px", width: "auto" }} />
    );
  }

export default SwsengIcon;
