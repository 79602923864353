import React from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { keyframes } from "@mui/system";

const black = {
  50: "#999",
  100: "#888",
  200: "#777",
  300: "#666",
  400: "#313131",
  500: "#212121",
  600: "#111111",
  700: "#101010",
  800: "#010101",
  900: "#000",
};

const gradientFlow = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
const ColorButton = styled(Button)(({ theme }) => ({
  color: "#eee",
  background: `linear-gradient(-45deg, #7fdaff, #ffa63d, #ff3d77, #ffa63d)`,
  backgroundSize: "500%",
  fontFamily: "LibreFranklin",
  fontWeight: "600",
  fontSize: "1rem",
  letterSpacing: "1px",
  lineHeight: "1.5",
  textTransform: "none",
  textWrap: "nowrap",
  padding: "0.25rem 1rem",
  borderRadius: "2rem",
  transition: "all 150ms ease",
  border: `none`,
  boxShadow: `none`,
  animation: `gradient 10s linear infinite`,
  "&:hover": {
    // backgroundColor: black[600],
    color: "#fff",
  },
}));

export default function CTAButton(
  {
    component,
    to,
    buttonTitle,
    buttonIcon,
    clickAction,
    boxStyles,
    alignSelf,
    children,
  },
) {
  return (
    <Box
      sx={{
        alignSelf: alignSelf ? alignSelf : "center",
        display: "flex",
        ...boxStyles,
      }}
    >
      <ColorButton
        onClick={clickAction}
        component={component}
        to={to}
        sx={{
          display: "flex",
          borderRadius: 8,
          paddingLeft: 1.25,
          paddingRight: 1.25,
        }}
        endIcon={buttonIcon}
      >
        <>
          {buttonTitle}
          {children}
        </>
      </ColorButton>
    </Box>
  );
}
