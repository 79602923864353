import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import { Box, CircularProgress, Grow, InputLabel, Stack } from "@mui/material";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { loadDocument } from "../../Document/documentSlice.js";
import axios from "axios";
import ShareLink from "./ShareLink.jsx";

function ShareModal(
  {
    show,
    handleClose,
  },
) {
  const [users, setUsers] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedElement, setSelectedElement] = React.useState(null);

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const apiKey = process.env.REACT_APP_API_KEY;
  const user = useSelector((state) => state.user);
  const currentDocument = useSelector((state) => state.document);
  const { viewer: viewers } = currentDocument;
  const dispatch = useDispatch();

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user.token}`,
      },
    };
    async function getUsers() {
      try {
        const response = await axios.get(
          apiEndpoint + "/user/list/",
          config,
        );

        // console.log(response);
        // Add current user to Viewers to be excluded
        // console.log("response.data");
        // console.log(response.data);
        // console.log("viewers");
        // console.log(response.data);

        const viewerList = viewers.map((v) => v.username).concat(
          user.username.toLowerCase(),
        );
        const userList = response.data
          .map((data) => ({
            value: data.username.toLowerCase(),
            label: data.username,
          })).filter((uL) => !viewerList.includes(uL.value));

        // const userListFilter = userList.filter((data) => !(viewerList.includes(data.value)));
        // console.log("userList");
        // console.log(userList);
        // console.log("viewers");
        // console.log(viewerList);
        // console.log(userList.filter((uL) => vi))
        setUsers(userList);
      } catch (error) {
        // console.log(error);
      }
    }
    getUsers();
  }, [show, viewers]);

  const handleDocumentAddViwer = () => {
    setIsLoading(true);
    async function documentAddViewer() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
      const payload = {
        username: selectedUser.value,
        actor_element: selectedElement === null ? null : selectedElement.value,
      };
      try {
        // console.log(payload);
        const response = await axios.post(
          apiEndpoint + `/document/${currentDocument.id}/addViewer`,
          payload,
          config,
        );
        // console.log(response);
        setIsLoading(false);
        dispatch(
          loadDocument({ ...currentDocument, viewer: response.data.viewer }),
        );
        setSelectedElement(null);
        setSelectedUser(null);
      } catch (error) {
        // console.log(error);
        setSelectedUser(null);
        setSelectedElement(null);
        setIsLoading(false);
      }
    }
    documentAddViewer();
  };

  const handleDocumentRemoveViwer = (username) => {
    // setIsLoading(true);
    async function documentRemoveViewer(username) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
        data: {
          username: username,
        },
      };
      const payload = {
        username: username,
      };
      // console.log("username");
      // console.log(username);
      // console.log("payload");
      // console.log(payload);
      try {
        // console.log(payload);
        const response = await axios.delete(
          apiEndpoint + `/document/${currentDocument.id}/addViewer`,
          config,
        );
        // console.log(response);
        // setIsLoading(false);
        dispatch(
          loadDocument({ ...currentDocument, viewer: response.data.viewer }),
        );
        setSelectedElement(null);
        setSelectedUser(null);
      } catch (error) {
        // console.log(error);
        setSelectedUser(null);
        setSelectedElement(null);
        // setIsLoading(false);
      }
    }
    documentRemoveViewer(username);
  };

  return (
    <React.Fragment>
      <Modal
        size="md"
        aria-labelledby="Share Modal"
        aria-describedby="Share scripts with Swseng users and publish scripts publicly"
        open={show}
        onClose={() => {
          setUsers(null);
          setSelectedElement(null);
          setSelectedUser(null);
          handleClose();
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            margin: "4rem",
            height: "auto",
            minWidth: "600px",
            maxWidth: "800px",
            background: "#f8f8f8",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <DialogTitle>
            <Typography level="h3">
              Share the script
              <Typography level="h3" variant="soft" sx={{ ml: 1, mr: 5 }}>
                {currentDocument.title}
              </Typography>
            </Typography>
            <ModalClose variant="plain" sx={{ m: 1 }} />
          </DialogTitle>
          <Box>
            <InputLabel>
              Add actors and viewers to script:
            </InputLabel>
            <Select
              onChange={(e) => {
                setSelectedUser(e);
              }}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={viewers[0]}
              isSearchable={true}
              isClearable={true}
              value={selectedUser}
              name="username"
              options={users}
              placeholder="Select a user ..."
            />
          </Box>
          {selectedUser !== null
            ? (
              <Grow in={selectedUser !== null}>
                <Stack
                  mt={4}
                  direction="row"
                  alignItems={"baseline"}
                  spacing={2}
                  justifyContent={"space-evenly"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#dddddd",
                      borderRadius: "36px",
                      alignItems: "baseline",
                      padding: "4px 16px 4px 8px",
                      height: "38px",
                      width: "fit-content",
                    }}
                  >
                    <Avatar
                      sx={{ backgroundColor: "#f8f8f8", mr: "4px" }}
                      alt={selectedUser.label.toUpperCase()}
                    />
                    <Typography>{selectedUser.value}</Typography>
                  </Box>
                  <Box>
                    <Select
                      onChange={(e) => {
                        setSelectedElement(e);
                      }}
                      filterOption={(option) => !viewers.includes(option.value)}
                      value={selectedElement}
                      placeholder="Share entire script"
                      options={currentDocument.elements.map((element) => ({
                        value: element,
                        label: element,
                      }))}
                    />
                    <Typography
                      sx={{ position: "relative", bottom: "4rem" }}
                    >
                      Highlight Element?
                    </Typography>
                  </Box>
                  <Button
                    disabled={isLoading}
                    onClick={() => handleDocumentAddViwer()}
                  >
                    {isLoading ? <CircularProgress /> : ("Share")}
                  </Button>
                </Stack>
              </Grow>
            )
            : (null)}
          {viewers.length > 0 && (
            <>
              <Typography level="h4" mt={1}>
                Shared with:
              </Typography>
              <Box
                sx={{
                  margin: "0px",
                  padding: "0px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                <Table
                  hoverRow
                >
                  <tbody>
                    {currentDocument.viewer.map((v) => {
                      return (
                        <tr
                          key={v.user_id}
                        >
                          <td>
                            <Stack
                              direction="row"
                              alignItems={"center"}
                            >
                              <Avatar
                                sx={{ backgroundColor: "#f8f8f8" }}
                                alt={v.username.toUpperCase()}
                              />
                              <Typography
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {v.username}
                              </Typography>
                            </Stack>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems={"center"}
                            >
                              <Typography
                                sx={{
                                  overflowY: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {v.actor_element}
                              </Typography>
                            </Stack>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                handleDocumentRemoveViwer(v.username)}
                            >
                              Unshare?
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Box>
            </>
          )}
          <ShareLink currentDocument={currentDocument} user={user} />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
export default ShareModal;
