import * as React from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import Box from "@mui/joy/Box";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

export default function ConfirmModal(
  { setOpen, open, modalTitle, modalBodyText, modalButtons },
) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          sx={{ fontWeight: "lg", mb: 1 }}
        >
          {modalTitle}
        </Typography>
        <Typography id="modal-desc" textColor="text.tertiary">
          {modalBodyText}
        </Typography>
        <Box display="flex" marginLeft={"auto"} marginRight={"auto"} width={"75%"} justifyContent={"space-around"} mt={4}>
          {modalButtons.map((b) => {
            return (
              <Button key={b.label} color={b.color} onClick={b.action}>{b.label}</Button>
            );
          })}
        </Box>
      </Sheet>
    </Modal>
  );
}
