import React from "react";
import { useSelector } from "react-redux";
import { createBrowserRouter,  RouterProvider } from "react-router-dom";
import Editor from "./Components/Editor/Editor.jsx";
// import Landing from "./Components/Pages/Landing.jsx"
import Landing from "./Components/Pages/NewLanding.jsx"
import SignIn from "./Components/Pages/Login.jsx"
import Register from "./Components/Pages/Register.jsx"
import ErrorPage from "./Components/Pages/error-page.jsx"
import ConfirmEmail from "./Components/Pages/ConfirmEmail.jsx"
import ResetPassword from "./Components/Pages/ResetPassword.jsx";
import ConfirmResetPassword from "./Components/Pages/ConfirmResetPassword.jsx";
import Presentation from "./Components/Presentation/Presentation.jsx";
import PresentationPublic from "./Components/Public/PresentationPublic.jsx";

function App() {
  
  const user = useSelector(state => state.user); 
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/Editor",
      element: (user.isAuthenticated ? <Editor /> : <SignIn />),
    },
    {
      path: "/SignIn",
      element: <SignIn />,
    },
    {
      path: "/Presentation",
      element: (user.isAuthenticated ? <Presentation /> : <SignIn />),
    },
    {
      path: "/Register",
      element: <Register />,
    },
    {
      path: "/ConfirmEmail/:uid/:token",
      element: <ConfirmEmail />,
    },
    {
      path: "/ResetPassword",
      element: <ResetPassword />,
    },
    {
      path: "/ResetPassword/:token",
      element: <ConfirmResetPassword />,
    },
    {
      path: "/p/:uuid",
      element: <PresentationPublic />,
      errorElement: <ErrorPage />,
    },

  ])
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
