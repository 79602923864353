import React from "react";
import StaffName from "../Staff/StaffName";
import RowSpacer from "../Common/RowSpacer";
import PresentationDialogue from "./PresentationDialogue";

export default function SectionElementExpanded({
  element,
  highlighted,
  rowStyle,
  dialogue,
  section,
  page,
  emptySpacer,
}) {

  const sectionElementTransitions =
    section.elements.find((elem) => elem.elementName === element)["transitions"];
  
  const [showStageDirections, setShowStageDirections] = React.useState(false);
  const [stageDirections, setStageDirections] = React.useState(
    sectionElementTransitions === undefined ? [] : sectionElementTransitions
    // T 1
    // { page: 2, direction: "enter", offset: 44 },
    // { page: 3, direction: "exit", offset: 22 },
    // { page: 3, direction: "enter", offset: 44 },
    // { page: 4, direction: "exit", offset: 22 },
    // { page: 4, direction: "enter", offset: 44 },
    // T 2
    // { direction: "enter", page: 2, offset: 100 },
    // { direction: "exit", page: 5, offset: 50 },
    // { direction: "enter", page: 5, offset: 50 },
    //
    // T 3
    // { direction: "enter", page: 2, offset: 15 },
    // { direction: "exit", page: 2, offset: 20 },
    // { direction: "enter", page: 2, offset: 35 },
    // { direction: "exit", page: 2, offset: 40 },
    // { direction: "enter", page: 2, offset: 55 },
    // { direction: "exit", page: 2, offset: 60 },
  );
  // console.log("section SectionElementExpanded:")
  // console.log(section)
  const menuOptionsSection = [{
    label: "Transitions",
    action: () => {
      return setShowStageDirections(true);
    },
  }, {
    label: "Rename",
    action: () => {
      return alert("In progress");
    },
  }];

  return (
    <div className="staff-container-section-elements">
      <StaffName
        elementName={element}
        menuOptions={[]}
        highlighted={highlighted}
        isSLine={false}
      />
      <PresentationDialogue
        stageDirections={stageDirections}
        setStageDirections={setStageDirections}
        setShowStageDirections={setShowStageDirections}
        showStageDirections={showStageDirections}
        highlighted={highlighted}
        rowStyle={rowStyle}
        element={element}
        dialogue={dialogue}
        sectionName={section.sectionName}
        page={page}
      />
      <RowSpacer
        emptySpacer={emptySpacer}
      />
    </div>
  );
}
