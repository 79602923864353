export function getPrevStageDirection(stageDirection, page) {
  if (stageDirection === null) return null;
  const prevStageDirectionPage = stageDirection.reduce(
    (accum, curr) =>
      (curr.page > accum && curr.page < page) ? curr.page : accum,
    0,
  );
  if (prevStageDirectionPage === 0) return null;
  const prevStageDirection =
    stageDirection.filter((sD) => sD.page === prevStageDirectionPage).sort((
      a,
      b,
    ) => a.offset - b.offset).reverse()[0];
  return prevStageDirection;
}

export function getNextStageDirection(stageDirection, page) {
  if (stageDirection === null) return null;
  const nextStageDirection = stageDirection.find((sD) => sD.page > page);
  return (nextStageDirection === undefined) ? null : nextStageDirection;
}

export function stageDirectionsBackgroundStyling(page, stageDirection) {
  const exitedColor = "rgba(0, 0, 0, 0.15)";
  const transientColor = "rgba(0, 0, 0, 0.05)";
  const enteredColor = "rgba(0,0,0,0)";

  const localSD = stageDirection;
  if (
    localSD === undefined ||
    localSD.length === 0
  ) {
    return enteredColor;
  }
  const exactPageExists = localSD.some((sD) => sD.page === page);
  if (exactPageExists) {
    const exactPageStageDirections = localSD.filter((sD) => sD.page === page)
      .sort((a, b) => a.offset - b.offset);
    const formattedStageDirections = exactPageStageDirections.map((sD) => {
      if (sD.direction === "enter") {
        return `${exitedColor} ${sD.offset - 1
          }%, ${transientColor} ${sD.offset}%, ${enteredColor} ${sD.offset + 1
          }% `;
      }
      if (sD.direction === "exit") {
        return `${enteredColor} ${sD.offset - 1
          }%, ${transientColor} ${sD.offset}%, ${exitedColor} ${sD.offset + 1}% `;
      }
    }).toString();
    return `linear-gradient(to right, ${formattedStageDirections} )`;
  } else {
    const prevStageDirection = getPrevStageDirection(localSD, page);
    const nextStageDirection = getNextStageDirection(localSD, page);

    if (nextStageDirection === null && prevStageDirection === null) {
      return enteredColor;
    } else if (prevStageDirection === null && nextStageDirection) {
      if (nextStageDirection.direction === "enter") return exitedColor;
      if (nextStageDirection.direction === "exit") return enteredColor;
      if (nextStageDirection.direction === "delete-exit") return "#ff8484";
      if (nextStageDirection.direction === "delete-enter") return "#ff8484";
    } else {
      if (prevStageDirection.direction === "exit") return exitedColor;
      if (prevStageDirection.direction === "enter") return enteredColor;
      if (prevStageDirection.direction === "delete-exit") return "#ff8484";
      // if (prevStageDirection.direction === "delete-enter") return "#ff8484";
    }
  }
}

export default { stageDirectionsBackgroundStyling, getNextStageDirection, getPrevStageDirection };
