import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  createTheme,
  FormControl,
  InputLabel,
  ListItemText,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";

function FileNameInput(props) {
  const { title, setTitle } = props;
  // const [fileName, setFileName] = useState(fileNameProps.fileName);
  const theme = createTheme({
    palette: {
      ochre: {
        main: "#f8f8f8",
        light: "#ffffff",
        dark: "#979798",
        contrastText: "#242105",
      },
    },
  });
  return (
    <FormControl>
      <ThemeProvider theme={theme}>
        <InputLabel
          sx={{
            // display: "grid",
            top: "-2.25rem",
            left: "inherit",
            position: "absolute",
            justifyContent: "center",
            // transformOrigin: "top",
          }}
        >
          Filename
        </InputLabel>
        <TextField
          sx={{
            "& .MuiInputBase-input": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              backgroundColor: "theme.pallete.ochre.main",
              fontSize: "1.65rem",
              fontWeight: "450",
              py: "0",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid transparent",
            },
            "& .MuiInputBase-input:focus": {
              backgroundColor: "theme.pallete.ochre.light",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "inherit",
            },
          }}
          // size="small"
          fullWidth
          placeholder="Project title"
          // variant="outlined"
          id="File Name"
          // label="File"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
      </ThemeProvider>
    </FormControl>
  );
}

export default FileNameInput;
