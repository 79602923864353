import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import Add from "@mui/icons-material/Add";

export default function FileNameModal({ open, setOpen, saveFile }) {
  const [newFileName, setNewFileName] = useState("");
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Set filename</DialogTitle>
          <DialogContent>Filename is required to save or share a script.</DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Filename</FormLabel>
                <Input
                  value={newFileName}
                  onChange={(e) => setNewFileName(e.target.value)}
                  autoFocus
                  required
                />
              </FormControl>
              <Stack
                direction="row"
                justifyContent="end"
                // alignItems="space-beween"
                // spacing={3}
              >
                <Button
                  onClick={() => {
                    saveFile(newFileName);
                    setNewFileName("");
                    setOpen(false);
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
