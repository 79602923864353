import React, { useState } from "react";
import {
  Box,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
function ModalElementSelector(
  {
    editElement,
    editSection,
    setEditElement,
    elementsInSectionMeasure,
    elementsInSection,
  },
) {
  const handleAddElementSelect = (value) => {
    const currElement = elementsInSection.find((elem) =>
      elem.elementName === value
    );
    // setBounds(getBounds(currElement, measure));
    setEditElement(currElement);
  };

  const handleRemoveElementSelect = (value) => {
    const currElement = elementsInSectionMeasure.find((elem) =>
      elem.elementName === value
    );
    // setBounds(getBounds(currElement, measure));
    setEditElement(currElement);
  };
  // console.log("elementsInSectionMeasure: " + elementsInSectionMeasure);
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Sheet
          sx={{
            boxShadow: "0px 0px 5px grey",
            mr: 1,
            borderRadius: "8px",
            height: "auto",
            maxHeight: "250px",
            overflow: "auto",
            // overflowX: "clip",
          }}
        >
          <Table
            hoverRow
            stickyHeader
            borderAxis="x"
            // color={(isSubmitted && newSection.elements.length === 0) ? "danger" : ""}
          >
            <thead>
              <tr>
                <th>
                  <Box sx={{ display: "flex" }}>
                    <Typography level="h4">
                      Available Elements
                    </Typography>
                  </Box>
                </th>
              </tr>
            </thead>
            <tbody>
              {editSection.sectionName === ""
                ? (
                  null
                )
                : elementsInSection.map((element) => {
                  return (
                    <tr
                      key={element.elementName}
                      value={element.elementName}
                      onClick={() =>
                        handleAddElementSelect(element.elementName)}
                      selected={editElement.elementName ===
                        element.elementName}
                      style={editElement.elementName === element.elementName
                        ? {
                          background:
                            "var(--TableRow-hoverBackground, var(--joy-palette-background-level3, var(--joy-palette-neutral-300, #d1eaff)))",
                        }
                        : {}}
                    >
                      <td>{element.elementName}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Sheet>
        <Sheet
          sx={{
            boxShadow: "0px 0px 5px grey",
            borderRadius: "8px",
            // height: "50vh",
            height: "250px",
            overflow: "auto",
            // overflowX: "clip",
          }}
        >
          <Table
            hoverRow
            stickyHeader
            borderAxis="x"
          >
            <thead>
              <tr>
                <th>
                  <Box sx={{ display: "flex" }}>
                    <Typography level="h4">
                      Elements in
                    </Typography>
                    {editSection.sectionName &&
                      (
                        <Typography level="h4" variant="soft" sx={{ ml: 1 }}>
                          {editSection.sectionName}
                        </Typography>
                      )}
                  </Box>
                </th>
              </tr>
            </thead>
            <tbody>
              {elementsInSectionMeasure.length === 0 || editSection.sectionName === ""
                ? (null)
                : elementsInSectionMeasure.map((element) => {
                  return (
                    <tr
                      key={element.elementName}
                      // onClick={() => removeSectionElement(element)}
                      onClick={() =>
                        handleRemoveElementSelect(element.elementName)}
                      selected={editElement.elementName === element.elementName}
                      style={editElement.elementName === element.elementName
                        ? {
                          background:
                            "var(--TableRow-hoverBackground, var(--joy-palette-background-level3, var(--joy-palette-neutral-300, #d1eaff)))",
                        }
                        : {}}
                    >
                      <td>{element.elementName}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Sheet>
      </Box>
    </Box>
  );
}

export default ModalElementSelector;
