import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
function MeasureControl({ measure, setMeasure, handleClearCharacterFocus }) {
  const [nextMeasure, setNextMeasure] = useState(measure);

  const handleOnBlur = (e) => {
    // console.log("Target Value " + e.target.value)
    let parsedValue = parseInt(e.target.value);
    // console.log("Parsed target value" + parsedValue)
    if (parsedValue > 0 && !isNaN(parsedValue)) {
      setMeasure(parsedValue);
      setNextMeasure(parsedValue.toString());
    } 
    else {
      // console.log("Measure to string " + measure.toString())
       setNextMeasure(measure.toString());
     }
  };

  const handlePrevNext = (value) => {
    setNextMeasure(value);
    setMeasure(value);
  }
  const theme = createTheme({
    palette: {
      ochre: {
        main: "#c8c8c8",
        light: "#f8f8f8",
        dark: "#979798",
        contrastText: "#000",
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: "#d1eaff",
              boxShadow: "none",
            },
            backgroundColor: "#fff",
            border: "1px solid transparent",
            fontFamily: "Google Sans, sans-serif",
            letterSpacing: "1px",
            fontSize: "1.2rem",
            boxShadow: "none",
            textTransform: "none",
            margin: "0 0 0 0",
            color: "#000",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "36px",
            border: "none",
            marginBottom: "0",
            marrginTop: "0",
          },
        },
      },
    },
  });

  return (
    <FormControl>
      <Box
        style={{
          display: "inline-flex",
          border: "1px solid rgb(217, 217, 217)",
          width: "fit-content",
          borderRadius: "14px",
          paddingBottom: "0px",
          marginBottom: "0px",
          height: "fit-content",
          alignSelf: "center",
        }}
      >
        <ThemeProvider theme={theme}>
          <InputLabel
            sx={{
              // display: "grid",
              top: "-2.25rem",
              left: "inherit",
              position: "absolute",
              // transformOrigin: "top",
            }}
          >
            Measure
          </InputLabel>
          <Button
            sx={{ my: 0, py: 0, borderRadius: "14px 2px 2px 14px" }}
            color="ochre"
            disabled={measure === 1}
            onClick={() => {
              handleClearCharacterFocus();
              handlePrevNext(() => measure - 1);
            }}
          >
            {"Prev"}
          </Button>
          <TextField
            sx={{
              // width: "fit-content",
              "& .MuiInputBase-input:focus": {
                backgroundColor: "#fff",
                padding: "4px 14px 4px 14px",
              },
              input: { textAlign: "center", minWidth: "3ch", maxWidth: "4ch" },
            }}
            value={nextMeasure}
            // variant="outlined"
            onChange={(e) => setNextMeasure(e.target.value)}
            onBlur={(e) => handleOnBlur(e)}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              min: 1,
            }}
          />
          <Button
            sx={{ my: 0, py: 0, borderRadius: "2px 14px 14px 2px" }}
            color="ochre"
            onClick={() => {
              handleClearCharacterFocus();
              handlePrevNext(() => measure + 1);
            }}
          >
            {"Next"}
          </Button>
        </ThemeProvider>
      </Box>
    </FormControl>
  );
}

export default MeasureControl;
