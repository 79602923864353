import React from "react";
import StaffName from "./StaffName";
import RowSpacer from "../Common/RowSpacer";
import DialogueInput from "./DialogueInput";
import Transitions from "./Transitions";

export default function StaffSectionElementExpanded({
  element,
  highlighted,
  rowStyle,
  dialogue,
  setDialogue,
  section,
  page,
  emptySpacer,
  staffElements,
  setStaffElements,
  characterFocus,
  setCharacterFocus,
}) {
  // we neet a get state function that is gonna retreive the state from
  // its gonna be someting like this and maybe I need to hand down setStaffElements
  // all the way down here

  // Make a a function that uses the setStaffElement function
  // but points directly at the spot in the state that we want
  //
  //
  // The idea of a sectionElement, the element
  const sectionElement = staffElements.sections.find((sec) =>
    sec.sectionName === section.sectionName
  ).elements.find((elem) => elem.elementName === element);

  const sectionElementTransitions =
    staffElements.sections.find((sec) =>
      sec.sectionName === section.sectionName
    ).elements.find((elem) => elem.elementName === element)["transitions"];
  
  // if (sectionElementTransitions !== undefined) {
  //   console.log(
  //     `StaffSectionElementExpanded sectionElementTransitions: ${element}`,
  //   );
  //   console.log(sectionElementTransitions);
  //   console.log("sectionElement");
  //   console.log(sectionElement);
  // }
  // else {
  //   console.log(section)
  //   console.log(element)
  // }
  const [stageDirections, setStageDirections] = React.useState(
    sectionElementTransitions === undefined ? [] : sectionElementTransitions,
  );
  const [showStageDirections, setShowStageDirections] = React.useState(false);

  const handleCloseStageDirections = () => {
    // Get section element
    const sectionElement = staffElements.sections.find((sec) =>
      sec.sectionName === section.sectionName
    ).elements.find((elem) => elem.elementName === element);
    // const sectionElementTransitions =
    //   staffElements.sections.find((sec) =>
    //     sec.sectionName === section.sectionName
    //   ).elements.find((elem) => elem.elementName === element)["transitions"];
    //
    // Save transitions to the section element
    const newSectionElement = {...sectionElement, transitions: stageDirections }
    const nextSection = { ...section, elements: section.elements.map((sE) => sE.elementName === element ? newSectionElement : sE)}
    // Insert it into the proper place in the array
    let nextStaffElements = {
      ...staffElements ,
      sections: staffElements.sections.map((sec) => sec.sectionName === section.sectionName ? nextSection : sec)
    }
    // console.log("nextStaffElements")
    // console.log(nextStaffElements)
    setShowStageDirections(false);
    setStaffElements(nextStaffElements)

  };
  // const [stageDirections, setStageDirections] =
  // React.useState(
  // [
  //   { page: 1, direction: "enter", offset: 44 },
  //   { page: 2, direction: "exit", offset: 22 },
  //   { page: 2, direction: "enter", offset: 44 },
  //   { page: 3, direction: "exit", offset: 22 },
  //   { page: 3, direction: "enter", offset: 44 },
  //   { page: 5, direction: "exit", offset: 22 },
  //   { page: 5, direction: "enter", offset: 44 },
  //   { page: 6, direction: "exit", offset: 22 },
  //   { page: 6, direction: "enter", offset: 44 },
  // ];
  // );

  // const [staffElements, setStaffElements] = useState({
  //   elements: documentState.elements,
  //   sections: documentState.sections,
  // });
  // console.log("section StaffSectionElementExpanded:")
  // console.log(section)
  // console.log("element StaffSectionElementExpanded:")
  // console.log(element)
  // console.log("stageDirections StaffSectionElementExpanded:");
  // console.log(stageDirections);
  const menuOptionsSection = [{
    label: "Transitions",
    action: () => {
      return setShowStageDirections(true);
    },
  } //   {
    //   label: "Rename",
    //   action: () => {
    //     return alert("In progress");
    //   },
    // }
  ];

  return (
    <div className="staff-container-section-elements">
      <StaffName
        elementName={element}
        menuOptions={menuOptionsSection}
        highlighted={false}
        isSLine={false}
      />
      {showStageDirections
        ? (
          <Transitions
            handleCloseStageDirections={handleCloseStageDirections}
            staffElements={staffElements}
            setStaffElements={setStaffElements}
            stageDirections={stageDirections}
            setStageDirections={setStageDirections}
            setShowStageDirections={setShowStageDirections}
            showStageDirections={showStageDirections}
            highlighted={highlighted}
            rowStyle={rowStyle}
            element={element}
            dialogue={dialogue}
            section={section}
            page={page}
          />
        )
        : (
          <DialogueInput
            stageDirections={stageDirections}
            rowStyle={"staff-row"}
            element={element}
            dialogue={dialogue}
            section={section.sectionName}
            characterFocus={characterFocus}
            setCharacterFocus={setCharacterFocus}
            setDialogue={setDialogue}
            page={page}
          />
        )}
      <RowSpacer
        emptySpacer={emptySpacer}
      />
    </div>
  );
}
