import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: null,
  token: null,
  firstName: null,
  lastName: null,
  email: null,
  isAuthenticated: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // REgister
    //
    // Login
    //
    setUserData(state, action) {
      return {
        ...state,
        username: action.payload.user.username,
        firstName: action.payload.user.first_name,
        lastName: action.payload.user.last_name,
        email: action.payload.user.email,
        token: action.payload.token,
        isAuthenticated: true,
      };
    },
    // Logout
    logoutUser(state, action) {
      return {
        ...state,
        username: null,
        token: null,
        firstName: null,
        lastName: null,
        email: null,
        isAuthenticated: false,
      };
    },
  },
});

export const { setUserData, logoutUser } = userSlice.actions;
export default userSlice.reducer;
