import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FileNameInput from "./FileNameInput.jsx";
import FileSectionElement from "./FileSectionElements.jsx";
import MeasureControl from "./MeasureControl.jsx";
import ShareModal from "../Modals/Share/ShareModal.jsx";
import ShareButton from "./ShareButton.jsx";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LastSaved from "./LastSaved.jsx";
import AvatarButton from "./AvatarButton.jsx";
// import SwsengLogo from "../../static/images/SWSENG_Logo_150DPI.png";
import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";
import { loadDocument } from "../Document/documentSlice.js";
function ToolBar(props) {
  const user = useSelector((state) => state.user);
  const viewer = useSelector((state) => state.document.viewer);
  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0].toUpperCase()}`,
    };
  }

  const {
    handleLogOut,
    handleCreateNewFile,
    handleDownloadFile,
    handleShowFileModal,
    handleShowSectionModal,
    handleShowElementModal,
    documentId,
    measure,
    setMeasure,
    handleClearCharacterFocus,
    setTitle,
    title,
    handleSaveFile,
  } = props;
  const [showShareModal, setShowShareModal] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };
  const handleShowShareModal = () => {
    setShowShareModal(true);
  };
  const emptyFile = {
    title: "",
    dialogue: [],
    sections: [],
    elements: [],
    measure: null,
    id: null,
    created_at: null,
    updated_at: null,
    owner: null,
    viewer: [],
  };
  return (
    <>
      <Box className="top-toolbar-container">
            <Box
          sx={{
            display: "grid",
            gridColumn: "1 / 1",
            alignSelf: "center",
            paddingLeft: 2,
            // maxWidth: "fit-content",
          }}
        >
          <IconButton
            sx={{ borderRadius: 2, padding: 0 }}
            component={RouterLink}
            to={"/"}
            onClick={() => dispatch(loadDocument(emptyFile))}
          >
            <img src={SwsengLogo} style={{ height: "56px", width: "auto"}}/>
            {/* 
            <SwsengIcon sx={{ minHeight: "2rem" }} />

            */}
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "2 / 5",
            alignSelf: "center",
            mr: 4,
          }}
        >
          <FileNameInput
            title={title}
            setTitle={setTitle}
          />
        </Box>
        <Box sx={{ display: "grid", gridColumn: "5 / 7", alignSelf: "center" }}>
          <FileSectionElement
            handleCreateNewFile={handleCreateNewFile}
            handleDownloadFile={handleDownloadFile}
            handleSaveFile={handleSaveFile}
            handleShowFileModal={handleShowFileModal}
            handleShowSectionModal={handleShowSectionModal}
            handleShowElementModal={handleShowElementModal}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "8 / 9",
            alignSelf: "center",
          }}
        >
          <MeasureControl
            measure={measure}
            setMeasure={setMeasure}
            handleClearCharacterFocus={handleClearCharacterFocus}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "10 / 11",
            alignSelf: "center",
          }}
        >
          <LastSaved />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "11 / 11",
            // backgroundColor: "#ddd",
            // borderRadius: 8,
            alignItems: "center",
            // pr: 1,
            // height: "fit-content",
            alignSelf: "center",
          }}
        >
          <ShareButton
            handleShow={documentId === null
              ? handleSaveFile
              : handleShowShareModal}
            disabled={documentId === null}
            viewer={viewer}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "12 / 12",
            gridRow: "1 / -1",
            maxWidth: "fit-content",
          }}
        >
          <AvatarButton
            handleLogOut={handleLogOut}
            user={user}
          />
        </Box>
      </Box>
      <ShareModal
        show={showShareModal}
        handleClose={handleCloseShareModal}
        handleShow={handleShowShareModal}
        title={title}
      />
    </>
  );
}

export default ToolBar;
