import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  dialogue: [],
  sections: [],
  elements: [],
  measure: null,
  id: null,
  created_at: null,
  updated_at: null,
  owner: null,
  viewer: [],
  public: false,
  uuid: null,
};
const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    loadDocument(state, action) {
      return {
        ...state,
        title: action.payload.title,
        dialogue: action.payload.dialogue,
        sections: action.payload.sections,
        elements: action.payload.elements,
        measure: 1,
        id: action.payload.id,
        created_at: action.payload.created_at,
        updated_at: action.payload.updated_at,
        owner: action.payload.owner,
        viewer: action.payload.viewer, 
        public: action.payload.public, 
        uuid: action.payload.uuid,
      };
    },
  },
  // Logout
});
//
export const { loadDocument } = documentSlice.actions;
export default documentSlice.reducer;
