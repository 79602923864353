import React from "react";

export default function SwipingArrows({touchMoveDistance,}) {
  return (
  <>
      <div
        style={{
          position: "fixed",
          right: "50%",
          top: "35%",
          display: touchMoveDistance === 0 ? "none" : "block",
          // Make this 2 on swipe and zero otherwise
          zIndex: "2",
        }}
      >
        <div
          className="arrow"
          style={{
            transform: "rotate(-90deg)",
            opacity: touchMoveDistance < 0
              ? `${-1 * touchMoveDistance / 150}`
              : 0,
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div
          className="arrow"
          style={{
            opacity: touchMoveDistance > 0
              ? `${touchMoveDistance / 150}`
              : 0,
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      </>
      )
}
