import React from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";

const black = {
  50: "#999",
  100: "#888",
  200: "#777",
  300: "#666",
  400: "#313131",
  500: "#212121",
  600: "#111111",
  700: "#101010",
  800: "#010101",
  900: "#000",
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: `${black[500]}`,
  fontFamily: "LibreFranklin",
  fontWeight: "400",
  fontSize: "1rem",
  letterSpacing: "1px",
  lineHeight: "1.5",
  textTransform: "none",
  textWrap: "nowrap",
  padding: "0.25rem 1rem",
  borderRadius: "2rem",
  transition: "all 150ms ease",
  border: `1px solid ${black[500]}`,
  boxShadow: `0 2px 1px rgba(45, 45, 60, 0.2), inset 0 1.5px 1px ${black[400]}, inset 0 -2px 1px ${black[600]}`,
  "&:hover": {
    backgroundColor: black[600],
    color: "#fbb32c",
  },
}));

export default function PillButtonLink(
  { component, to, buttonTitle, buttonIcon, clickAction, boxStyles, alignSelf },
) {
  return (
    <Box
      sx={{ alignSelf: alignSelf ? alignSelf : "center", display: "flex", ...boxStyles }}
    >
      <ColorButton
        onClick={clickAction}
        component={component}
        to={to}
        sx={{
          display: "flex",
          borderRadius: 8,
          paddingLeft: 1.25,
          paddingRight: 1.25,
        }}
        endIcon={buttonIcon}
      >
        {buttonTitle}
      </ColorButton>
    </Box>
  );
}
