import React from "react";
import DialogueInput from "./DialogueInput";
function SectionRow({ element, dialogue, setDialogue, page, characterFocus, setCharacterFocus, sectionExpanded, sectionName }) {
    const characterDialogue = dialogue.find((d) => (d.element === element && d.page === page))?.message;
    const handleDialogueChange = (e) => {
        {
            characterDialogue ?
                setDialogue(dialogue.map((d) => (d.element === element && d.page === page) ? { ...d, message: e.target.value } : d))
                :
                setDialogue([...dialogue, { element: element, message: e.target.value, page: page }])
        }

    }

    const handleOnCharacterFocus = () => {
        if (characterFocus === element) {
            setCharacterFocus("");
        } else {
            setCharacterFocus(element);
        }
    }
    return (
        (sectionExpanded.includes(sectionName) ?
            (
              <DialogueInput
                section={sectionName}
                rowStyle={"section-row"}
                element={element}
                dialogue={dialogue}
                characterFocus={characterFocus}
                setCharacterFocus={setCharacterFocus}
                setDialogue={setDialogue}
                page={page}
              />
            ) : (
                <div className="section-row"
                 style={{cursor: "inherit" }}>
                    {characterDialogue ? characterDialogue : '\u00A0'}
                </div >
            )
        )
    )
}

export default SectionRow;
