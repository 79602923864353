import * as React from "react";
import { Avatar, AvatarGroup, Box, Button, Typography } from "@mui/material";
import Erik from "../../static/images/Erik.jpg";
import ShareIcon from "@mui/icons-material/Share";
import { createTheme, ThemeProvider } from "@mui/material";

export default function AvatarButton({ handleShow, disabled, viewer }) {
  
  // // For styleOverrides in AvatarButton and Avatar 
  // const theme = createTheme({
  //   palette: {
  //     ochre: {
  //       main: "#c8c8c8",
  //       light: "#f8f8f8",
  //       dark: "#979798",
  //       contrastText: "#000",
  //     },
  //   },
  //
  //   components: {
  //     // Name of the component
  //     MuiAvatarGroup: {
  //       styleOverrides: {
  //         // Name of the slot
  //         root: {
  //           borderRadius: "36px",
  //           "&:hover, &.Mui-focusVisible": {
  //             // backgroundColor: "#eaeaea",
  //             cursor: "pointer",
  //             boxShadow: "3px 3px 1px rgba(0,0,0,0.4)",
  //           },
  //           transition: "box-shadow 0.2s",
  //           // border: "1px solid transparent",
  //           fontFamily: "Google Sans, sans-serif",
  //           padding: "2px 8px",
  //           letterSpacing: "1px",
  //           backgroundColor: "#ddd",
  //           fontSize: "1.2rem",
  //           boxShadow: "none",
  //           textTransform: "none",
  //           margin: "0 0 0 0",
  //           color: disabled ? "#888" : "#000",
  //         },
  //       },
  //     },
  //     MuiAvatar: {
  //       styleOverrides: {
  //         // Name of the slot
  //         root: {
  //           // "&:hover, &.Mui-focusVisible": {
  //           //   // backgroundColor: "#eaeaea",
  //           //   boxShadow: "3px 3px 0px rgba(0,0,0,0.4)",
  //           // },
  //           // transition: "box-shadow 0.2s",
  //         },
  //       },
  //     },
  //
  //     MuiListItemText: {
  //       styleOverrides: {
  //         root: {
  //           paddingLeft: "24px",
  //           paddingRight: "48px",
  //         },
  //       },
  //     },
  //   },
  // });
  // console.log(viewer.length);
  // console.log(disabled);
  return (
    <Button
      onClick={handleShow}
      sx={{
        display: "flex",
        borderRadius: "36px",
        "&:hover, &.Mui-focusVisible": {
        backgroundColor: "#ddd",
          cursor: "pointer",
          boxShadow: "3px 3px 1px rgba(0,0,0,0.4)",
        },
        transition: "box-shadow 0.2s",
        padding: "2px 8px",
        backgroundColor: "#ddd",
        width: "fit-content",
        textTransform: "none",
        margin: "0 0 0 0",
        color: disabled ? "#888" : "#000",
      }}
    >
      <ShareIcon sx={{ alignSelf: "center", marginRight: "4px" }} />
      <Typography sx={{ alignSelf: "center",
        fontSize: "1.35rem",
        fontFamily: "Google Sans, sans-serif",
        marginRight: "4px",
      }}>
        Share
      </Typography>
      <AvatarGroup
        total={viewer.length}
      >
        {viewer.slice(0, 1).map((f) => (
          <Avatar
            key={f.user_id}
            alt={f.username}
            src={f?.profilePic}
          >
            {f.username.toUpperCase()[0]}
          </Avatar>
        ))}
      </AvatarGroup>
    </Button>
  );
}
