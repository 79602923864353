import React, { useState } from "react";
import Copyright from "../Common/Copyright.jsx";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SwsengIcon from "../Layout/SwsengIcon.jsx";
import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Register() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registerSuccessful, setRegistSucessful] = useState(false);

  const [networkError, setNetworkError] = useState({
    isValid: true,
    errorMessage: "",
  });
  const [username, setUsername] = useState({
    username: "",
    isValid: true,
    errorMessage: "",
  });

  const [email, setEmail] = useState({
    email: "",
    isValid: true,
    errorMessage: "",
  });

  const passwordsMatch = password === passwordConfirm;

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    async function registerUser() {
      const body = JSON.stringify({
        username: username.username,
        password: password,
        email: email.email,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Swseng-Api-Key": apiKey,
        },
      };
      try {
        const response = await axios.post(
          apiEndpoint + "/user/register",
          body,
          config,
        );

        setRegistSucessful(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        // OFFLINE ERROR
        if (error.message) {
          setNetworkError({
            isValid: false,
            errorMessage: error.message,
          });
        }
        if (error.response) {
          // USERNAME ERROR
          if (error.response.data.username) {
            setUsername({
              ...username,
              isValid: false,
              errorMessage: error.response.data.username,
            });
          }

          // EMAIL ERROR
          if (error.response.data.email) {
            setEmail({
              ...email,
              isValid: false,
              errorMessage: error.response.data.email,
            });
          }
        }
      }
    }
    registerUser();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {registerSuccessful
          ? (
            <>
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SwsengIcon />
                <Box
                  sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h1" variant="h5" textAlign={"center"}>
                    Thank you for registering!
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="p" variant="p">
                  Please check your email (and possibly spam folder) for a link
                  to activate your account.
                </Typography>
              </Box>
            </>
          )
          : (
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SwsengIcon />
              <Typography component="h1" variant="h5">
                Register
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {!username.isValid &&
                  (
                    <Alert severity="error">
                      {username.errorMessage}
                    </Alert>
                  )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) =>
                    setUsername({
                      ...username,
                      username: e.target.value,
                      isValid: true,
                    })}
                  error={!username.isValid}
                  value={username.username}
                  id="username"
                  label="Username"
                  name="username"
                />
                {!email.isValid &&
                  (
                    <Alert severity="error">
                      {email.errorMessage}
                    </Alert>
                  )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      email: e.target.value,
                      isValid: true,
                    })}
                  error={!email.isValid}
                  value={email.email}
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  // autoComplete="email"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                />
                <TextField
                  margin="normal"
                  error={!passwordsMatch}
                  required
                  fullWidth
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  value={passwordConfirm}
                  name="passwordConfirm"
                  label="Confirm Password"
                  type="password"
                  id="passwordConfirm"
                />
                <Button
                  type="submit"
                  disabled={(username.username === "" | email.email === "" |
                    password === "" | passwordConfirm === "") ||
                    !passwordsMatch || isLoading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isLoading ? <CircularProgress /> : ("Create Account")}
                </Button>
              </Box>
            </Box>
          )}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
