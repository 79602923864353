import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Copyright from "../Common/Copyright";
import PresentationStaff from "./PresentationStaff";
import axios from "axios";
import { createTheme } from "@mui/material";
import FileModal from "../Modals/File/FileModal.jsx";
import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";
// import SwsengLogo from "../../static/images/SWSENG_Logo_150DPI.png";
import Select from "react-select";
import ShareButton from "../Layout/ShareButton.jsx";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import PresentationMeasureControl from "./PresentationMeasureControl.jsx";
import ShareModal from "../Modals/Share/ShareModal.jsx";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AvatarButton from "../Layout/AvatarButton.jsx";

import { logoutUser } from "../User/userSlice.js";
import { loadDocument } from "../Document/documentSlice.js";

function Presentation() {
  const documentState = useSelector((state) => state.document);
  const user = useSelector((state) => state.user);
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const localToken = localStorage.getItem("Swseng_Token");

  const [showFileModal, setShowFileModal] = React.useState(false);
  const [measure, setMeasure] = React.useState(1);
  const [selectedElement, setSelectedElement] = React.useState(null);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [sectionExpanded, setSectionExpanded] = React.useState([]);

  const handleCloseShareModal = () => setShowShareModal(false);

  const handleShowShareModal = () => setShowShareModal(true);

  const handleCloseFileModal = () => setShowFileModal(false);
  const handleShowFileModal = () => setShowFileModal(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emptyFile = {
    title: "",
    dialogue: [],
    sections: [],
    elements: [],
    measure: null,
    id: null,
    created_at: null,
    updated_at: null,
    owner: null,
    viewer: [],
  };

  const staffElements = {
    elements: documentState.elements,
    sections: documentState.sections,
  };
  const { title, dialogue, id: documentId, viewer } = documentState;

  const handleLogOut = () => {
    dispatch(loadDocument(emptyFile));
    dispatch(logoutUser());
    navigate("/");
  };
  const selectedElementOptions = documentState.elements.map((element) => ({
    value: element,
    label: element,
  }));

  function conversion(documentState) {
    let newDialogueSize = documentState.dialogue.reduce(
      (accum, curr) => curr.page > accum ? curr.page : accum,
      0,
    );
    // console.log(newDialogueSize);

    let newDialogue = Array.from(
      { length: newDialogueSize },
      (_, index) => ({ page: index + 1 }),
    );
    return newDialogue.map((page) => ({
      page: page.page,
      dialogue: documentState.dialogue.filter((d) => d.page === page.page),
    }));
  }
  // Fix and remove this from: Presentation, Landing, FileModal, ShareModal
  async function getUser() {
    const data = new FormData(event.currentTarget);
    const username = data.get("email");
    const password = data.get("password");
    const body = JSON.stringify({ username, password });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localToken,
      },
    };
    try {
      const response = await axios.post(
        apiEndpoint + "/user",
        body,
        config,
      );
      // console.log(response.data);
      // localStorage.setItem("Swsweng_token", "Token " + response.data.token )
      return response.data;
    } catch (error) {
      // console.error(error);
    }
  }

  if (localToken) {
    // console.log(localToken);
    getUser();
  }
  React.useEffect(() => {
    const user_viewer = documentState.viewer.find((v) =>
      v.username === user.username
    );
    if (user_viewer !== undefined) {
      const nextSectionsExpanded = staffElements.sections.filter((s) =>
        s.elements.find((se) => se.elementName === user_viewer.actor_element)
      ).map((s) => s.sectionName);
      setSectionExpanded(nextSectionsExpanded);
      return setSelectedElement(user_viewer.actor_element);
    }
    setSelectedElement(null);
  }, [documentState.id]);


  const handleSelectElement = (e) => {
    // console.log(`handleSelectedElementChange = ${e}`);
    // console.log(e);
    if (e === null) {
      setSelectedElement(null);
      setSectionExpanded([]);
    } else {
      // Sections Automatically Expand when  you choose a element actor
      const nextSectionsExpanded = staffElements.sections.filter((s) =>
        s.elements.find((se) => se.elementName === e.value)
      ).map((s) => s.sectionName);
      setSectionExpanded(nextSectionsExpanded);
      setSelectedElement(e.value);
    }
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  // console.log(conversion(documentState));
  function ToolBar() {
    return (
      <Box
        sx={{
          position: "relative",
          top: "0px",
          backgroundColor: "#f8f8f8",
          alignContent: "baseline",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          height: "fit-content",
          columnGap: "8px",
          rowGap: "8px",
          boxShadow: "0 4px 2px -2px gray",
          justifyContent: "start",
          zIndex: "2",
          py: "8px",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridColumn: "1 / 1",
            alignSelf: "center",
            // paddingLeft: 2,
            // maxWidth: "fit-content",
          }}
        >
          <IconButton
            sx={{ borderRadius: 2, padding: 0 }}
            component={RouterLink}
            onClick={() => dispatch(loadDocument(emptyFile))}
            to={"/"}
          >
            <img
              src={SwsengLogo}
              style={{
                maxHeight: "56px",
                width: "auto",
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              gridColumn: "2 / 11",
              gridRow: "1",
            },
            gridColumn: "2 / 5",
            gridRow: "1",
            alignSelf: "center",
          }}
        >
          {documentId !== null
            ? (
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  mr: 1,
                }}
              >
                {title}
              </Typography>
            )
            : (null)}
          <Box
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgb(217, 217, 217)",
              width: "fit-content",
              borderRadius: "14px",
              paddingBottom: "0px",
              marginBottom: "0px",
              height: "fit-content",
              alignSelf: "center",
            }}
          >
            <Button
              sx={{ color: "grey" }}
              onClick={() => handleShowFileModal()}
            >
              {documentId !== null ? "Change" : "Choose File"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            // display: "grid",
            alignSelf: "center",
            backgroundColor: "#f8f8f8",

            [theme.breakpoints.down("md")]: {
              gridColumn: "1 / 7",
              gridRow: "2",
              ml: 1,
            },
            gridColumn: "5 / 8",
            gridRow: "1",
            // width: "fit-content",
          }}
        >
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "14px",
              }),
            }}
            onChange={(e) => handleSelectElement(e)}
            isClearable={true}
            value={selectedElementOptions.filter(function(option) {
              return option.value === selectedElement;
            })}
            placeholder="Select element"
            options={selectedElementOptions}
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            alignSelf: "center",
            gridColumn: "9 / 10",
            gridRow: "1",
            [theme.breakpoints.down("md")]: {
              gridColumn: "8 / 12",
              gridRow: "2",
            },
          }}
        >
          <PresentationMeasureControl
            measure={measure}
            setMeasure={setMeasure}
          // handleClearCharacterFocus={handleClearCharacterFocus}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "11 / 11",
            alignItems: "center",
            alignSelf: "center",
            gridRow: "1",
          }}
        >
          {documentState.owner === user.username
            ? (
              <ShareButton
                handleShow={handleShowShareModal}
                disabled={documentId === null}
                viewer={viewer}
              />
            )
            : (null)}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridColumn: "12 / 12",
            gridRow: "1 / -1",
            maxWidth: "fit-content",
          }}
        >
          <AvatarButton
            handleLogOut={handleLogOut}
            user={user}
          />
        </Box>
      </Box>
    );
  }

  return (
    <div style={{ display: "grid", height: "100vh" }}>
      <ToolBar />
      <PresentationStaff
        elements={staffElements.elements}
        sections={staffElements.sections}
        dialogue={dialogue}
        page={measure}
        setPage={setMeasure}
        sectionExpanded={sectionExpanded}
        setSectionExpanded={setSectionExpanded}
        selectedElement={selectedElement}
        documentId={documentId}
      />
      <div className="bottom-toolbar-container">
        <Copyright />
      </div>
      <FileModal
        show={showFileModal}
        handleClose={handleCloseFileModal}
        showSharedWithMe={true}
      />
      <ShareModal
        show={showShareModal}
        handleClose={handleCloseShareModal}
        handleShow={handleShowShareModal}
        title={title}
      />
    </div>
  );
}

export default Presentation;
