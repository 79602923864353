import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import { Typography } from "@mui/joy";

export default function NewFileModal(
  { open, setOpen, title, setTitle, handleSaveFile, handleSetFileToEmpty },
) {
  const [newFileName, setNewFileName] = useState("");
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>
            <Typography level="h3">
              Save current script
              {title !== "" &&
                (
                  <Typography variant="soft">
                    {title}
                  </Typography>
                )}
              ?
            </Typography>
          </DialogTitle>
          <DialogContent>
            Save changes to current project or discard them and create a new
            project
          </DialogContent>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Button
                onClick={() => {
                  handleSetFileToEmpty();
                  setNewFileName("");
                  setOpen(false);
                }}
              >
                Discard Changes
              </Button>
              <Button
                onClick={() => {
                  handleSaveFile(newFileName);
                  handleSetFileToEmpty();
                  setNewFileName("");
                  setOpen(false);
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
