import React from "react";

function RowSpacer({emptySpacer}) {
  if (emptySpacer) {
    return (
      <>
        <div style={{padding: ".25rem"}} />
        <div style={{padding: ".25rem"}} />
      </>
    );
  } else {
    return (
      <>
        <div className="name-spacer" />
        <div className="row-spacer" />
      </>
    );
  }
}

export default RowSpacer;
