import React, { useRef, useState } from "react";
import { Alert, Box, Button, Input, Table, Typography } from "@mui/joy";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { InputLabel } from "@mui/material";
import ModalElementSelector from "./ModalElementSelector";
import ModalElementMeasureRange from "./ModalElementMeasureRange";
import MeasureControl from "../../Layout/MeasureControl";
function EditSectionModal(props) {
  const {
    show,
    handleClose,
    staffElements,
    setStaffElements,
    measure,
    setMeasure,
  } = props;
  const emptySection = {
    sectionName: "",
    elements: [
      {
        elementName: "",
        measureRanges: [
          [1, -1],
        ],
      },
    ],
  };

  const emptyElement = {
    elementName: "",
    measureRanges: [
      [1, -1],
    ],
  };
  // const editSectionRef = useRef(emptySection);
  //
  const { sections, elements } = staffElements;
  
  const maybeSection = sections.find((section) => section.sectionName === show.section);
  const [editSection, setEditSection] = useState(maybeSection ? maybeSection : emptySection);
  const [editElement, setEditElement] = useState(emptyElement);
  const [localMeasure, setLocalMeasure] = useState(measure);
  const [isEdited, setIsEdited] = useState(false);
  // const [isSubmitted, setIsSubmitted] = useState(false);


  function measureRangeCheck(mR, measure) {
    return ((mR[0] === 1 && mR[1] === -1) ||
      (mR[0] <= measure && mR[1] >= measure) ||
      (mR[0] <= measure && mR[1] === -1));
  }

  const getElementsInSectionMeasure = (section, measure) => {
    return section.elements.map((element) => {
      return element.measureRanges.map((mR) => {
        if (measureRangeCheck(mR, measure)) {
          return element;
        }
      });
    }).flat().filter((item) => item !== undefined);
  };

  // THis should be renamed to availableElements and need to create
  // a separate function that returns all the elements in a section
  const getElementsInSection = (section) => {
    const allElements = elements.map((element) => {
      return { ...emptyElement, elementName: element };
    });
    const elementsInSection = section.elements.map((element) => element);
    const elementsInSectionarray = allElements.map((elem) => {
      if (
        elementsInSection.find((item) => item.elementName === elem.elementName)
      ) {
        return elementsInSection.find((element) =>
          element.elementName === elem.elementName
        );
      } else {
        return elem;
      }
    });

    return elementsInSectionarray;
  };

  const handleClear = () => {
    setEditSection(emptySection);
    setEditElement(emptyElement);
    setIsEdited(false); 
    // setIsSubmitted(false);
  };

  // const handleDelete = () => {
  //   setStaffElements({
  //     elements: staffElements.elements,
  //     sections: [
  //       ...staffElements.sections.filter((section) =>
  //         section.sectionName !== editSection.sectionName
  //       ),
  //     ],
  //   });
  //   // setIsSubmitted(false);
  //   handleClear();
  //   handleClose();
  // };

  const handleSubmit = () => {
    // setIsSubmitted(true);
    setStaffElements({
      elements: staffElements.elements,
      sections: staffElements.sections.map((section) =>
        (section.sectionName === editSection.sectionName)
          ? editSection
          : section
      ),
    });
    handleClear();
    // handleClose();
  };

  const onHandleClose = () => {
    handleClear();
    // handleClose();
  };

  const handleEditSectionChange = (value) => {
    // console.log("handleEditSectionChange: " + value);
    // console.log(editSectionRef.current);
    if (value !== "") {
      const nextSection = sections.find((section) =>
        section.sectionName === value
      );
      // editSectionRef.current = nextSection;
      setEditSection(nextSection);
      setEditElement(emptyElement);
    }
  };

  const handleSectionChange = (e) => {
    if (e.target.value !== "") {
      setEditSection(
        sections.find((section) => section.sectionName === e.target.value),
      );
      setEditElement(emptyElement);
    }
  };

  const elementsInSectionMeasure = getElementsInSectionMeasure(
    editSection,
    localMeasure,
  );
  const elementsInSection = getElementsInSection(editSection).filter((
    element,
  ) =>
    !elementsInSectionMeasure.map((elem) => elem.elementName).includes(
      element.elementName,
    )
  );
  // console.log(elementsInSectionMeasure.map((e) =>e.elementName).toString());
  // console.log(elementsInSection.map((e) =>e.elementName).toString());
  // console.log("editSectionRef: " + editSectionRef.current.sectionName);
  return (
    <Box 
      // sx={{ 
      //   border: "1px solid rgba(0,0,0,0.2)", 
      //   borderRadius: "8px", 
      //   p: 2
      // }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            mb: 1,
            width: "50%",
            mr: 1,
          }}
        >
          <InputLabel>Section Name</InputLabel>
          <Select
            size="lg"
            // ref={editSectionRef.current}
            value={editSection.sectionName}
            placeholder="Choose a section…"
            // onChange={(event) => {
            //   console.log("Select: " + event.target.value);
            //   // handleSectionChange(event);
            // }}
          >
            {sections.map((section) => {
              return (
                <Option
                  key={section.sectionName}
                  value={section.sectionName}
                  onClick={() => {
                    // console.log("Option: " + section.sectionName);
                    handleEditSectionChange(section.sectionName);
                  }}
                >
                  {section.sectionName}
                </Option>
              );
            })}
          </Select>
        </Box>
        <Box
          sx={{
            pt: 3,
            ml: 1,
            display: "flex",
            justifyContent: "center",
            width: "50%",
          }}
        >
          <MeasureControl
            sx={{ pt: 2 }}
            measure={localMeasure}
            setMeasure={setLocalMeasure}
            handleClearCharacterFocus={() => {}}
          />
        </Box>
      </Box>
      <ModalElementSelector
        measure={localMeasure}
        setMeasure={setLocalMeasure}
        editElement={editElement}
        editSection={editSection}
        setEditElement={setEditElement}
        elementsInSectionMeasure={elementsInSectionMeasure}
        elementsInSection={elementsInSection}
        isEdited={isEdited}
      />
      <ModalElementMeasureRange
        editElement={editElement}
        setEditElement={setEditElement}
        editSection={editSection}
        setEditSection={setEditSection}
        measure={localMeasure}
        elementsInSectionMeasure={elementsInSectionMeasure}
        elementsInSection={elementsInSection}
        setStaffElements={setStaffElements}
        staffElements={staffElements}
        setIsEdited={setIsEdited}
        isEdited={isEdited}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}

export default EditSectionModal;
