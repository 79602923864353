import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Input,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Table,
  Typography,
} from "@mui/joy";
import { InputLabel } from "@mui/material";

function EditElementModal(
  { show, handleClose, staffElements, setStaffElements, setDialogue, dialogue },
) {
  const { elements, sections } = staffElements;
  const [editElementName, setEditElementName] = useState("");
  const [newElementName, setNewElementName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const handleEditElementName = () => {
    setDialogue(
      dialogue.map((d) =>
        (d.element === editElementName) ? { ...d, element: newElementName } : d
      ),
    );
    setStaffElements({
      // sections: [...staffElements.sections],
      sections: sections.map((section) =>
        section.elements.map((element) => element.elementName).includes(
            editElementName,
          )
          ? {
            ...section,
            elements: section.elements.map((ele) =>
              ele.elementName === editElementName
                ? { ...ele, elementName: newElementName }
                : ele
            ),
          }
          : section
      ),
      elements: elements.map((element) =>
        (element === editElementName) ? newElementName : element
      ),
    });
    setNewElementName("");
    setEditElementName("");
  };

  const handleDeleteElement = () => {
    setDialogue(dialogue.filter((d) => d.element !== editElementName));
    setStaffElements({
      // sections: [...staffElements.sections],
      sections: sections.map((section) =>
        section.elements.map((element) => element.elementName).includes(
            editElementName,
          )
          ? {
            ...section,
            elements: section.elements.filter((ele) =>
              ele.elementName !== editElementName
            ),
          }
          : section
      ),
      elements: elements.filter((element) => element !== editElementName),
    });
    setNewElementName("");
    setEditElementName("");
  };

  const isElementNameUnique = elements.filter((element) =>
    element !== editElementName
  ).find((element) => element === newElementName);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          boxShadow: "0px 0px 5px grey",
          borderRadius: "8px",
          width: "50%",
          height: "50vh",
          maxHeight: "350px",
          overflow: "auto",
        }}
      >
        <Table stickyHeader hoverRow>
          <thead>
            <tr>
              <th>Select Element</th>
            </tr>
          </thead>
          <tbody style={{}}>
            {elements.map((element) => {
              return (
                <tr
                  key={element}
                  value={element}
                  onClick={() => {
                    setEditElementName(element);
                    setNewElementName(element);
                  }}
                  selected={editElementName === element}
                  style={editElementName === element
                    ? {
                      background:
                        "var(--TableRow-hoverBackground, var(--joy-palette-background-level3, var(--joy-palette-neutral-300, #d1eaff)))",
                    }
                    : {}}
                >
                  <td>{element}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>

      <Box sx={{ ml: 2, width: "50%", alignSelf: "center" }}>
        {elements.filter((element) => element !== editElementName).find((
            element,
          ) => element === newElementName
          )
          ? (
            <Alert sx={{ justifyContent: "center"}} color="danger">
              ✨ Element names must be unique ✨
            </Alert>
          )
          : ("")}
        <InputLabel className="h4">Edit Element Name:</InputLabel>
        <Input
          size="lg"
          // type="text"
          value={newElementName}
          onChange={(e) => setNewElementName(e.target.value.toUpperCase())}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            disabled={isElementNameUnique || newElementName === ""}
            variant="solid"
            onClick={() => {
              handleEditElementName();
            }}
          >
            Save Changes
          </Button>
        </Box>

        <InputLabel sx={{ mt: 4 }}>Remove Element?</InputLabel>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            disabled={isElementNameUnique || newElementName === ""}
            variant="solid"
            color="danger"
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            Delete Element?
          </Button>
          <Modal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
          >
            <ModalDialog layout="center">
              <DialogTitle>
                Are you sure you want to delete element {editElementName}?{" "}
              </DialogTitle>
              <DialogContent>
                <br />
                All dialogue and information will be lost, perhaps you would
                like to rename them?
              </DialogContent>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  variant="solid"
                  color="danger"
                  onClick={() => {
                    handleDeleteElement();
                    setOpenDeleteModal(false);
                  }}
                >
                  Confirm Delete
                </Button>
                <Button
                  variant="solid"
                  color="primary"
                  onClick={() => {
                    setOpenDeleteModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </ModalDialog>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}

export default EditElementModal;
