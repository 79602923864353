import React from "react";
import { stageDirectionsBackgroundStyling } from "../Common/TransitionStyling";
import EditTransitionToolbar from "./EditTransitionToolbar.jsx";
import TransitionStaffLine from "./TransitionStaffLine.jsx";
export default function Transitions(
  {
    element,
    section,
    dialogue,
    page,
    rowStyle,
    highlighted,
    setShowStageDirections,
    setStageDirections,
    stageDirections,
    showStageDirections,
    staffElements,
    setStaffElements,
    handleCloseStageDirections,
  },
) {
  const [stageDirectionAction, setStageDirectionAction] = React.useState(null);

  // React.useEffect(() => {
  //   if (stageDirections !== undefined) {
  //     console.log("useEffect stageDirections");
  //     console.log(stageDirections);
  //   }
  // }, [stageDirections]);

  let backgroundStyling = stageDirectionsBackgroundStyling(
    page,
    stageDirections,
  );
  const actualCharacterDialogue = dialogue.find((
    d,
  ) => (d.element === element && d.page === page))?.message;
  const characterFocusId = section !== undefined
    ? `${element}-${section}`
    : `${element}`;

  React.useEffect(() => {
    setStageDirectionAction(null);
  }, [showStageDirections]);
  return (
    <>
      <EditTransitionToolbar
        handleCloseStageDirections={handleCloseStageDirections}
        stageDirections={stageDirections}
        setStageDirections={setStageDirections}
        showStageDirections={showStageDirections}
        setShowStageDirections={setShowStageDirections}
        setStageDirectionAction={setStageDirectionAction}
        stageDirectionAction={stageDirectionAction}
      />
      <TransitionStaffLine
        page={page}
        element={element}
        section={section}
        actualCharacterDialogue={actualCharacterDialogue}
        backgroundStyling={backgroundStyling}
        showStageDirections={showStageDirections}
        transitionAction={stageDirectionAction}
        setTransitions={setStageDirections}
        transitions={stageDirections}
        characterFocusId={characterFocusId}
        staffElements={staffElements}
        setStaffElements={setStaffElements}
        rowStyle={rowStyle}
      />
    </>
  );
}
