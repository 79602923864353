import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import SwsengIcon from "../Layout/SwsengIcon";
import Copyright from "../Common/Copyright";
import axios from "axios";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;

export default function ResetPassword() {
  const [email, setEmail] = useState({
    email: "",
    isValid: true,
    errorMessage: "",
  });
  const [resetTokenSuccess, setResetTokenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    async function getPasswordResetToken() {
      const body = JSON.stringify({ email: email.email });
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Swseng-Api-Key": apiKey,
        },
      };
      try {
        const response = await axios.post(
          apiEndpoint + "/password_reset/",
          body,
          config,
        );
        setIsLoading(false);
        setResetTokenSuccess(true);
      } catch (error) {
        setIsLoading(false);
        setEmail({
          ...email,
          errorMessage: error.response.data.email,
          isValid: false,
        });
      }
    }
    getPasswordResetToken();
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SwsengIcon />
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography mb={3} component="h1" variant="h4" textAlign={"center"}>
            Forgot your password?
          </Typography>
          {resetTokenSuccess
            ? (
              <Typography
                mb={3}
                component="h1"
                variant="h5"
                textAlign={"center"}
              >
                An email to reset your password has been sent!
              </Typography>
            )
            : (
              <>
                <Typography component="p" variant="p" textAlign={"center"}>
                  {"Don't worry, enter your email address and we'll send you a link to reset your password. "}
                </Typography>
                {!email.isValid &&
                  (
                    <Alert severity="error">
                      {email.errorMessage}
                    </Alert>
                  )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoFocus={true}
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      email: e.target.value,
                      isValid: true,
                    })}
                  error={!email.isValid}
                  value={email.email}
                  id="email"
                  placeholder="Enter your email address"
                  label="Email Address"
                  name="email"
                  type="email"
                  // autoComplete="email"
                />
                <Button
                  disabled={email.email === "" || isLoading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isLoading ? <CircularProgress /> : ("Reset Password")}
                </Button>
              </>
            )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
