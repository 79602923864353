import React, { useEffect, useState } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/material/Box";
import { Avatar, AvatarGroup, Button, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/joy/Table";

import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { loadDocument } from "../../Document/documentSlice.js";

function FileModal(
  {
    show,
    handleClose,
    showSharedWithMe,
    setTitle,
    setStaffElements,
    setDialogue,
  },
) {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState("1");
  const handleMUIChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [files, setFiles] = useState({
    my_files: [],
    shared_with_me: [],
  });
  const [fileSelection, setFileSelection] = useState("");

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const user = useSelector((state) => state.user);

  const buttonTheme = createTheme({
    palette: {
      ochre: {
        main: "#c8c8c8",
        light: "#f8f8f8",
        dark: "#979798",
        contrastText: "#000",
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // "&:hover, &.Mui-focusVisible": {
            //   backgroundColor: "#d1eaff",
            //   boxShadow: "none",
            // },
            backgroundColor: "#fff",
            padding: "0 1rem 0px 1rem",
            borderRadius: "14px",
            border: "1px solid grey",
            fontFamily: "Google Sans, sans-serif",
            letterSpacing: "1px",
            fontSize: "1.2rem",
            boxShadow: "none",
            textTransform: "none",
            margin: "0 0 0 0",
            color: "#000",
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            paddingLeft: "24px",
            paddingRight: "48px",
          },
        },
      },
    },
  });

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user.token}`,
      },
    };
    async function getDocuments() {
      try {
        const response = await axios.get(
          apiEndpoint + "/document/",
          config,
        );
        // console.log(response);
        setFiles(() => (response.data));
      } catch (error) {
        // console.log(error);
      }
    }
    getDocuments();
  }, [show]);

  const handleLoadFile = (fileID) => {
    async function getDocument(fileID) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
      try {
        const response = await axios.get(
          apiEndpoint + `/document/${fileSelection}/`,
          config,
        );

        // Hacky way to allow useState based variables to update when a file is loaded
        // until I replace all instances with redux
        if (!showSharedWithMe) {
          setDialogue(response.data.dialogue);
          setTitle(response.data.title);
          setStaffElements({
            elements: response.data.elements,
            sections: response.data.sections,
          });
        }
        // console.log(response.data);
        dispatch(loadDocument(response.data));
      } catch (error) {
        console.log(error);
      }
    }
    getDocument(fileID);
  };

  const handleLoadViewerFile = (fileID) => {
    async function getDocument(fileID) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
      try {
        const response = await axios.get(
          apiEndpoint + `/document/${fileSelection}/view`,
          config,
        );
        console.log(response.data);
        dispatch(loadDocument(response.data));

        // setData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getDocument(fileID);
  };

  const handleFileSelection = (e) => {
    console.log("Parsed INT from event " + parseInt(e));
    // setFileSelection(e.target.value);
  };

  function ControlledTabsExample() {
    return (
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleMUIChange}
            aria-label="lab API tabs example"
          >
            <Tab label="My Files" value="1" />
            {showSharedWithMe && <Tab label="Shared with me" value="2" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box
            sx={{
              mb: 4,
              border: "1px solid grey",
              borderRadius: 1,
              height: "50vh",
              overflow: "auto",
            }}
          >
            <Table hoverRow stickyHeader>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th style={{ textAlign: "center" }}>Created on</th>
                  <th style={{ textAlign: "center" }}>Last Updated</th>
                  <th>Shared with</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {files.my_files.map((file) => {
                  const createdDate = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(Date.parse(file.created_at));
                  const createdTime = new Intl.DateTimeFormat("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(Date.parse(file.created_at));
                  const updatedTime = new Intl.DateTimeFormat("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(Date.parse(file.updated_at));
                  const updatedDate = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(Date.parse(file.updated_at));
                  return (
                    <tr
                      key={file.id}
                      value={file.id}
                      onClick={() => setFileSelection(file.id)}
                      selected={fileSelection === file.id}
                      style={fileSelection === file.id
                        ? {
                          background:
                            "var(--TableRow-hoverBackground, var(--joy-palette-background-level3, var(--joy-palette-neutral-300, #d1eaff)))",
                        }
                        : {}}
                    >
                      <td>{file.title}</td>
                      <td style={{ textAlign: "center" }}>
                        <Typography>
                          {createdDate}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="soft"
                            sx={{ width: "fit-content" }}
                          >
                            {createdTime}
                          </Typography>
                        </Box>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Typography>
                          {updatedDate}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="soft"
                            sx={{ width: "fit-content" }}
                          >
                            {updatedTime}
                          </Typography>
                        </Box>
                      </td>
                      <td>
                        <AvatarGroup
                          total={file.viewer.length}
                          max={2}
                          sx={{ width: "fit-content" }}
                        >
                          {file.viewer.slice(0, 3).map((f) => (
                            <Avatar
                              key={f.user_id}
                              alt={f.username}
                              src={f?.profilePic}
                            >
                              {f.username.toUpperCase()[0]}
                            </Avatar>
                          ))}
                        </AvatarGroup>
                      </td>
                      <td>{file.owner}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
          <ThemeProvider theme={buttonTheme}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={fileSelection === ""}
                onClick={() => {
                  handleLoadFile(fileSelection);
                  setFileSelection("");
                  handleClose();
                }}
                type="submit"
                // variant="primary"
              >
                Open
              </Button>
            </Box>
          </ThemeProvider>
        </TabPanel>
        <TabPanel value="2">
          <Box
            sx={{
              mb: 4,
              border: "1px solid grey",
              borderRadius: 1,
              height: "50vh",
              overflow: "auto",
            }}
          >
            <Table hoverRow stickyHeader>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th style={{ textAlign: "center" }}>Last Updated</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {files.shared_with_me.map((file) => {
                  const updatedTime = new Intl.DateTimeFormat("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(Date.parse(file.updated_at));
                  const updatedDate = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(Date.parse(file.updated_at));
                  return (
                    <tr
                      key={file.id}
                      value={file.id}
                      onClick={() => setFileSelection(file.id)}
                      selected={fileSelection === file.id}
                      style={fileSelection === file.id
                        ? {
                          background:
                            "var(--TableRow-hoverBackground, var(--joy-palette-background-level3, var(--joy-palette-neutral-300, #d1eaff)))",
                        }
                        : {}}
                    >
                      <td>{file.title}</td>
                      <td style={{ textAlign: "center" }}>
                        <Typography>
                          {updatedDate}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="soft"
                            sx={{ width: "fit-content" }}
                          >
                            {updatedTime}
                          </Typography>
                        </Box>
                      </td>
                      <td>{file.owner}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
          <ThemeProvider theme={buttonTheme}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={fileSelection === ""}
                onClick={() => {
                  handleLoadViewerFile(fileSelection);
                  setFileSelection("");
                  handleClose();
                }}
                type="submit"
                // variant="primary"
              >
                Open
              </Button>
            </Box>
          </ThemeProvider>
        </TabPanel>
      </TabContext>
    );
  }

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={show}
        onClose={() => handleClose()}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "75%",
            height: "auto",
            position: "absolute",
            top: "10vh",
            // minWidth: "50%",
            maxWidth: "1080px",
            background: "#f8f8f8",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <ControlledTabsExample />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
export default FileModal;
