import React from "react";
import StaffName from "./StaffName";
import DialogueInput from "./DialogueInput";
// import SectionName from "./SectionName";
import SectionHeaderLabel from "../Common/SectionHeaderLabel";
import SectionRow from "./SectionRow";
import RowSpacer from "../Common/RowSpacer";
import { Button, Typography } from "@mui/joy";
import SectionHeaderButton from "../Common/SectionHeaderButton";
import StaffSectionElementExpanded from "./StaffSectionElementExpanded";
function Sections(
  {
    staffElements,
    setStaffElements,
    setSectionExpanded,
    sectionExpanded,
    sections,
    dialogue,
    page,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    handleShowSectionModal,
  },
) {
  function measureRangeCheck(mR, page) {
    return ((mR[0] === 1 && mR[1] === -1) || (mR[0] <= page && mR[1] >= page) ||
      (mR[0] <= page && mR[1] === -1));
  }
  // const handleTransitionOpen = () => {
  //   alert("Dummy action");
  // };

  // const sectionMenuOptions = [{
  //   label: "Transitions",
  //   action: handleTransitionOpen,
  // }, {
  //   label: "Rename",
  //   action: () => {
  //     return alert("In progress");
  //   },
  // }];
  function getElementsInSection(page, section) {
    return section.elements.map((element) => {
      return element.measureRanges.map((mR) => {
        if (measureRangeCheck(mR, page)) {
          return element.elementName;
        }
      });
    }).flat().filter((item) => item !== undefined);
  }

  function handleExpandSection(sectionName) {
    setCharacterFocus("");
    {
      sectionExpanded.includes(sectionName)
        ? setSectionExpanded(sectionExpanded.filter((x) => x !== sectionName))
        : setSectionExpanded([...sectionExpanded, sectionName]);
    }
  }

  return (
    <>
      {/* if there are no sections render null */}
      {/* For each section render a section name and sectior row that can be expanded */}
      {sections.map((section) => {
        const elementsInSection = getElementsInSection(page, section);
        const isSectionExpanded = sectionExpanded.includes(section.sectionName);
        const annotationScaleFactor = elementsInSection.length > 0
          ? elementsInSection.length
          : 1;
        return (
          <div key={section.sectionName}>
            <div className="staff-container">
              <SectionHeaderButton
                sectionName={section.sectionName}
                onClickAction={() => handleExpandSection(section.sectionName)}
                isSectionExpanded={isSectionExpanded}
              />
              {section.elements.length > 0
                ? (
                  <>
                    <StaffSectionElementExpanded
                      // key={element}
                      staffElements={staffElements}
                      setStaffElements={setStaffElements}
                      element={elementsInSection[0]}
                      highlighted={false}
                      rowStyle={"staff-row-presentation"}
                      dialogue={dialogue}
                      setDialogue={setDialogue}
                      characterFocus={characterFocus}
                      setCharacterFocus={setCharacterFocus}
                      section={section}
                      page={page}
                      emptySpacer={false}
                    // emptySpacer={index ===
                    //   elementsInSection.length - 1}
                    />
                    {
                      /*
                    <StaffName
                      elementName={elementsInSection[0]}
                      menuOptions={sectionMenuOptions}
                      isSLine={true}
                    />
                    <SectionRow
                      sectionName={section.sectionName}
                      sectionExpanded={sectionExpanded}
                      element={elementsInSection[0]}
                      dialogue={dialogue}
                      characterFocus={characterFocus}
                      setCharacterFocus={setCharacterFocus}
                      setDialogue={setDialogue}
                      page={page}
                    />

                    */
                    }
                  </>
                )
                : (
                  <div
                    style={{
                      display: "grid",
                      gridColumn: "2 / 5",
                      padding: "0.5rem 0",
                      borderRadius: "8px",
                      alignContent: "center",
                      justifyContent: "center",
                      backgroundColor: "rgb(236, 236, 236)",
                    }}
                  >
                    <Typography level="h4">
                      Section contains no elements
                    </Typography>
                    <Button
                      onClick={() =>
                        handleShowSectionModal("2", section.sectionName)}
                    >
                      Click to add elements
                    </Button>
                  </div>
                )}
              {/* if sectionExpeded === true and selection has more than one element:  add a spacer */}
            </div>
            {isSectionExpanded
              ? elementsInSection.map((element, index) => {
                if (index > 0) {
                  return (
                    <div
                      key={element}
                      className="staff-container-section-elements"
                    >
                      <StaffSectionElementExpanded
                        key={element}
                        staffElements={staffElements}
                        setStaffElements={setStaffElements}
                        element={element}
                        highlighted={false}
                        rowStyle={"staff-row-presentation"}
                        dialogue={dialogue}
                        setDialogue={setDialogue}
                        characterFocus={characterFocus}
                        setCharacterFocus={setCharacterFocus}
                        section={section}
                        page={page}
                        emptySpacer={index ===
                          elementsInSection.length - 1}
                      />
                      {
                        /*

                      <StaffName
                        elementName={element}
                        isSLine={false}
                        menuOptions={sectionMenuOptions}
                      />
                      <DialogueInput
                        rowStyle={"staff-row"}
                        element={element}
                        dialogue={dialogue}
                        section={section.sectionName}
                        characterFocus={characterFocus}
                        setCharacterFocus={setCharacterFocus}
                        setDialogue={setDialogue}
                        page={page}
                      />

                        */
                      }

                      <RowSpacer
                        emptySpacer={index === elementsInSection.length - 1}
                      />
                    </div>
                  );
                }
              })
              : (null)}
          </div>
        );
      })}
    </>
  );
}

export default Sections;
