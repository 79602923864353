import React from "react";
import StaffName from "./StaffName";
import DialogueInput from "./DialogueInput";
import RowSpacer from "../Common/RowSpacer";
import { Typography } from "@mui/joy";

function Elements(
  {
    sectionElementsToExclude,
    elements,
    dialogue,
    page,
    setDialogue,
    characterFocus,
    setCharacterFocus,
  },
) {
  const elementsNotInASection = elements.filter((elem) =>
    !sectionElementsToExclude.includes(elem)
  );
  // console.log(elementsNotInASection);
  if (elementsNotInASection.length > 0) {
    return (
      <>
        {elements.length > 0 && (
          <div
            style={{
              padding: "1rem 0 1rem 0",
              width: "113ch",
              textAlign: "center",
              fontSize: "inherit",
              fontFamily: "Courier New",
              marginLeft: "12ch",
            }}
          >
            <Typography level="h3" sx={{ letterSpacing: "2px" }}>
              Elements
            </Typography>
          </div>
        )}
        {elementsNotInASection.map((element, index) => {
          return (
            <div key={element} className="staff-container">
              <StaffName
                elementName={element}
                menuOptions={[]}
                highlighted={false}
                isSLine={false}
              />
              <DialogueInput
                rowStyle={"staff-row"}
                element={element}
                dialogue={dialogue}
                characterFocus={characterFocus}
                setCharacterFocus={setCharacterFocus}
                setDialogue={setDialogue}
                page={page}
              />

              <RowSpacer
                emptySpacer={index === elementsNotInASection.length - 1}
              />
            </div>
          );
        })}
      </>
    );
  }
}

export default Elements;
