import React from "react";
import { Box, InputLabel, FormControl } from "@mui/material";
import BasicMenu from "./BasicMenu.jsx";

export default function FileSectionElement(props) {
  const {
    handleDownloadFile,
    handleShowFileModal,
    handleShowSectionModal,
    handleShowElementModal,
    handleSaveFile,
    handleCreateNewFile,
  } = props;

  const fileMenuProps = {
    menuName: "File",
    borderStyling: "14px 0 0 14px",
    menuOptions: [
      { displayName: "New", action: handleCreateNewFile },
      { displayName: "Open", action: handleShowFileModal },
      { displayName: "Save", action: handleSaveFile },
      { displayName: "Download", action: handleDownloadFile },
    ],
  };

  const sectionMenuProps = {
    menuName: "Section",
    borderStyling: "0 14px 14px 0",
    menuOptions: [
      { displayName: "Create Section", action: handleShowSectionModal, tabIndex: "1" },
      { displayName: "Edit Section", action: handleShowSectionModal, tabIndex: "2" },
      { displayName: "Rename / Delete Section", action: handleShowSectionModal, tabIndex: "3" },
    ],
  };

  const elementMenuProps = {
    menuName: "Element",
    borderStyling: "0 0 0 0",
    menuOptions: [
      { displayName: "Add Element", action: handleShowElementModal, tabIndex: "1" },
      { displayName: "Edit Element", action: handleShowElementModal, tabIndex: "2" },
    ],
  };

  return (
    <FormControl>
    <Box
      style={{
        backgroundColor: "#fff",
        display: "inline-flex",
        border: "1px solid rgb(217, 217, 217)",
        width: "fit-content",
        borderRadius: "14px",
        paddingBottom: "0px",
        marginBottom: "0px",
        height: "fit-content",
        alignSelf: "center",
      }}
    >
      <InputLabel
        sx={{
          // display: "grid",
          top: "-2.25rem",
          left: "inherit",
          position: "absolute",
          justifyContent: "center",
          // transformOrigin: "top",
        }}
      >
       Actions 
      </InputLabel>
      <BasicMenu
        menuProps={fileMenuProps}
      />
      <div
        style={{
          borderRight: "1px solid rgb(217, 217, 217)",
          borderLeft: "1px solid rgb(217, 217, 217)",
          width: "fit-content",
          paddingBottom: "0px",
          marginBottom: "0px",
          height: "fit-content",
        }}
      >
        <BasicMenu
          menuProps={elementMenuProps}
        />
      </div>
      <BasicMenu
        menuProps={sectionMenuProps}
      />
    </Box>
    </FormControl>
  );
}
