import React, { useState } from "react";
import { Alert, Box, Button, Input, Table, Typography } from "@mui/joy";
import { InputLabel } from "@mui/material";

function AddElementModal(
  { show, handleClose, staffElements, setStaffElements },
) {
  const { elements } = staffElements;
  const [elementName, setElementName] = useState("");

  const handleAddElement = (e) => {
    setStaffElements({
      ...staffElements,
      elements: [...staffElements.elements, elementName],
    });
    setElementName("");
  };

  const isElementNameUnique = elements.find((element) =>
    element === elementName
  );
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "50%", mr: 2, alignSelf: "center" }}>
        {isElementNameUnique
          ? (
            <Alert sx={{ justifyContent: "center"}} color="danger">
              ✨ Element names must be unique ✨
            </Alert>
          )
          : ("")}
        <InputLabel>
          Enter Element Name:
        </InputLabel>
        <Input
          size="lg"
          placeholder="Element Name"
          value={elementName}
          onChange={(e) => {
            setElementName(e.target.value.toUpperCase());
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            disabled={isElementNameUnique || elementName === ""}
            variant="solid"
            onClick={(e) => handleAddElement(e)}
          >
            Add Element
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          boxShadow: "0px 0px 5px grey",
          borderRadius: "8px",
          width: "50%",
          height: "50vh",
          maxHeight: "350px",
          overflow: "auto",
        }}
      >
        <Table stickyHeader>
          <thead>
            <tr>
              <th>Existing Elements</th>
            </tr>
          </thead>
          <tbody style={{}}>
            {elements.map((element) => {
              return (
                <tr
                  key={element}
                >
                  <td>{element}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default AddElementModal;
