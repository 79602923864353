import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Copyright from "../Common/Copyright";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../User/userSlice";
import axios from "axios";
import SwsengIcon from "../Layout/SwsengIcon";

// const apiEndpoint = "http://146.190.169.91:8000"
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;
// console.log(apiKey);

// console.log(process.env);

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState({
    isValid: true,
    errorMessage: "",
  });

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRememberMeCheckBox = () => {
    setRememberMe(!rememberMe)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.removeItem("Swseng_token");
    setIsLoading(true);
    async function getUser() {
      const data = new FormData(event.currentTarget);
      const username = data.get("username");
      const password = data.get("password");
      const body = JSON.stringify({ username, password });
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Swseng-Api-Key": apiKey,
        },
      };
      try {
        const response = await axios.post(
          apiEndpoint + "/user/login",
          body,
          config,
        );
        // console.log(response.data);
        setIsLoading(false);
        if (rememberMe) {
        localStorage.setItem("Swseng_token", response.data.token);

        }
        dispatch(
          setUserData(response.data),
        );
        navigate("/");
      } catch (error) {
        // console.error(error);
        // OFFLINE ERROR
        setIsLoading(false);
        if (error.message) {
          setErrorMessage({
            isValid: false,
            errorMessage: error.message,
          });
        }
        // RESPONSE ERRORS
        if (error.response) {
          // console.log(error.response.data.non_field_errors);
          if (error.response.data.non_field_errors) {
            setErrorMessage({
              isValid: false,
              errorMessage: error.response.data.non_field_errors[0],
            });
          }
        }
      }
    }

    getUser();
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SwsengIcon />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {!errorMessage.isValid &&
              (
                <Stack
                  spacing={2}
                >
                  <Alert
                    sx={{
                      justifyContent: "center",
                    }}
                    severity="warning"
                    pb={2}
                  >
                    {errorMessage.errorMessage}
                  </Alert>
                  <Alert severity="info">
                    Users must verify their account via email prior to logging
                    in
                  </Alert>
                </Stack>
              )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox checked={rememberMe} onChange={handleRememberMeCheckBox} color="primary" />}
              label="Remember me"
            />
            <Button
              disabled={username === "" || password === "" || isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <CircularProgress /> : ("Sign In")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to={"/ResetPassword"} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to={"/Register"} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
