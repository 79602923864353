import * as React from "react";
import { Avatar, IconButton } from "@mui/material";
import MenuList from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ListItemText, ThemeProvider } from "@mui/material";

export default function AvatarButton({ user, handleLogOut }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0].toUpperCase()}`,
    };
  }

  const menuProps = {
    menuOptions: [
      {
        displayName: "View profile",
        action: handleClose,
      },
      {
        displayName: "Logout",
        action: handleLogOut,
      },
    ],
  };

  const theme = createTheme({
    palette: {
      ochre: {
        main: "#c8c8c8",
        light: "#f8f8f8",
        dark: "#979798",
        contrastText: "#000",
      },
    },

    components: {
      // Name of the component
      MuiIconButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            borderRadius: menuProps.borderStyling,
            border: "1px solid transparent",
            fontFamily: "Google Sans, sans-serif",
            letterSpacing: "1px",
            fontSize: "1.2rem",
            boxShadow: "none",
            textTransform: "none",
            margin: "0 0 0 0",
            color: "#000",
            transition: "box-shadow 0.2s",
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&:hover, &.Mui-focusVisible": {
              // backgroundColor: "#eaeaea",
              boxShadow: "3px 3px 0px rgba(0,0,0,0.4)",
            },
            transition: "box-shadow 0.2s",
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            paddingLeft: "24px",
            paddingRight: "48px",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <IconButton
        sx={{
          display: "grid",
          gridColumn: "12 / 12",
          gridRow: "1 / -1",
          maxWidth: "fit-content",
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="ochre"
      >
        <Avatar
          sx={{ width: 48, height: 48 }}
          alt={user.username}
          {
            // src={Danny}
            ...stringAvatar(user.username)
          }
        >
        </Avatar>
      </IconButton>
      <MenuList
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuProps.menuOptions.map((option) => (
          <MenuItem
            key={option.displayName}
          >
            <ListItemText
              onClick={(e) => {
                option.action(option.tabIndex);
                handleClose(e);
              }}
            >
              {option.displayName}
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </ThemeProvider>
  );
}
