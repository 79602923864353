import React, { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Input,
  Option,
  Select,
  Stack,
  Typography,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
} from "@mui/joy";
import { InputLabel } from "@mui/material";

function RenameDeleteSection(
  { staffElements, setStaffElements },
) {
  const emptySection = {
    sectionName: "",
    elements: [],
  };


  const [editSection, setEditSection] = useState(emptySection);
  const [nextSectionName, setNextSectionName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const existingSectionNames = staffElements.sections.map((s) => s.sectionName).filter((eS) =>
    eS !== editSection.sectionName
  );
  console.log("existingSectionNames: ");
  console.log(existingSectionNames);
  const handleEditSectionChange = (value) => {
    // console.log("handleEditSectionChange: " + value);
    // console.log(editSectionRef.current);
    if (value !== "") {
      const nextSection = staffElements.sections.find((section) =>
        section.sectionName === value
      );
      // editSectionRef.current = nextSection;
      setEditSection(nextSection);
      setNextSectionName(nextSection.sectionName);
    }
  };

  const handleDeleteSection = () => {
    setStaffElements({
      elements: staffElements.elements,
      sections: staffElements.sections.filter((s) =>
        s.sectionName !== editSection.sectionName
      ),
    });
  }
  const handleChangeSectionName = () => {

    setStaffElements({
      elements: staffElements.elements,
      sections: staffElements.sections.map((s) =>
        s.sectionName === editSection.sectionName
          ? ({ ...s, sectionName: nextSectionName })
          : s
      ),
    });
    console.log("nextSectionName: " + nextSectionName);
    console.log("editSection sectionName: " + editSection.sectionName);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          mb: 1,
          width: "50%",
          mr: 1,
        }}
      >
        <InputLabel variant="h4">Section Name</InputLabel>
        <Select
          size="lg"
          // ref={editSectionRef.current}
          value={editSection.sectionName}
          placeholder="Choose a section…"
          // TODO MAKE THIS NULL sectionID
          // onChange={(event) => {
          //   console.log("Select: " + event.target.value);
          //   // handleSectionChange(event);
          // }}
        >
          {staffElements.sections.map((section) => {
            return (
              <Option
                key={section.sectionName}
                value={section.sectionName}
                onClick={() => {
                  // console.log("Option: " + section.sectionName);
                  handleEditSectionChange(section.sectionName);
                }}
              >
                {section.sectionName}
              </Option>
            );
          })}
        </Select>
      </Box>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Box
          // p={3}
          p={1}
          sx={{
            // border: "1px solid grey",
            borderRadius: "8px",
            boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
            // minHeight: "200px",
          }}
        >
          <Box pb={2}>
            <Box
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography level="h4" color="neutral">Rename Section</Typography>
            </Box>
            <Box
              // sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
            >
              <InputLabel
                sx={{ display: "inline-flex" }}
              >
                Section Name
                {(nextSectionName === "" && editSection.sectionName !== "")
                  ? (
                    <Typography color="danger">
                      &nbsp; <ErrorOutlineIcon />
                      &nbsp; Section Name required
                    </Typography>
                  )
                  : ("")}
                {existingSectionNames.includes(
                    nextSectionName,
                  )
                  ? (
                    <Typography color="danger">
                      &nbsp; <ErrorOutlineIcon />
                      &nbsp; Section names must be unique
                    </Typography>
                  )
                  : ("")}
              </InputLabel>
              <Box
                sx={{ display: "flex" }}
              >
                <Input
                  sx={{ width: "50%" }}
                  // size="lg"
                  // label={"Section Name"}
                  disabled={editSection.sectionName === ""}
                  value={nextSectionName}
                  onChange={(e) => setNextSectionName(e.target.value)}
                />
                <Button
                  sx={{ marginLeft: "1rem" }}
                  disabled={editSection.sectionName === ""}
                  color="primary"
                  onClick={() => handleChangeSectionName()}
                >
                  Change Section Name
                </Button>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography level="h4" color="neutral">Delete Section</Typography>
            </Box>
            <Box
              sx={{ display: "flex", paddingBottom: "1rem" }}
            >
              <Stack
                sx={{ width: "50%" }}
              >
                <Typography variant="p">
                  Would you like to delete section &quot;{" "}
                  {editSection.sectionName} &quot; ?
                </Typography>
                <Typography variant="p">
                  This is permanent and cannot be reversed
                </Typography>
              </Stack>
              <Button
                sx={{ ml: 3, alignSelf: "center" }}
                disabled={editSection.sectionName === ""}
                color="danger"
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete {editSection.sectionName}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      >
        <ModalDialog layout="center">
          <DialogTitle>
            Are you sure you want to delete Section {editSection.sectionName}?{" "}
          </DialogTitle>
          <DialogContent>
            <br />
            A section is a grouping of elements, so their text will not be lost. However, the order and information associated with the section will be.
          </DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                handleDeleteSection();
                setOpenDeleteModal(false);
              }}
            >
              Confirm Delete
            </Button>
            <Button
              variant="solid"
              color="primary"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </Box>
  );
}

export default RenameDeleteSection;
