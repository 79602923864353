import { configureStore } from "@reduxjs/toolkit";
import userReducer from './Components/User/userSlice';
import documentReducer from './Components/Document/documentSlice.js';

export default configureStore({
  reducer: {
    document: documentReducer,
    user: userReducer,
  }
})
