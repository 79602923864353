import React, { useEffect, useRef, useState } from "react";
import { Input } from "@mui/joy";
import { stageDirectionsBackgroundStyling } from "../Common/TransitionStyling";
function DialogueInput(
  {
    element,
    section,
    page,
    dialogue,
    setDialogue,
    characterFocus,
    setCharacterFocus,
    stageDirections,
    rowStyle,
  },
) {
  /// Maybe Resturcture this so that:
  // The Element Dialogue object from the dialogue is held in the state (or maybe just the message)
  // and
  //
  //

  // console.log(
  //   dialogue.find((d) => (d.element === element && d.page === page))?.message,
  // );
  // const [characterDialogue, setCharacterDialogue] = useState( getCharacterDialogue(element, page));
  const actualCharacterDialogue = dialogue.find((
    d,
  ) => (d.element === element && d.page === page))?.message;
  const characterFocusId = section !== undefined
    ? `${element}-${section}`
    : `${element}`;
  // console.log("characterFocusId: " + characterFocusId);
  function StaffInput(props) {
    function getCharacterDialogue(element, page) {
      const potentialDialogue = dialogue.find((
        d,
      ) => (d.element === element && d.page === page))?.message;
      if (potentialDialogue === undefined) {
        return "";
      } else {
        return potentialDialogue;
      }
    }

    const handleOutsideClick = (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        // console.log("Outside Click");
        setCharacterFocus("");
        handleDialogueChange(message);
      }
    };

    const [message, setMessage] = useState(getCharacterDialogue(element, page));
    const newRef = useRef(null);

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    });

    const handleChange = (event) => {
      setMessage(event.target.value);
    };
  // console.log("characterFocusId")
  // console.log(characterFocusId)
  // console.log("characterFocus")
  // console.log(characterFocus)

    return (
      <Input
        sx={{
          fontFamily: "Courier New",
          "--Input-minHeight": 0,
          "--Input-paddingInline": '4px',
          "--Input-letterSpacing": 'inherit',
          "--Input-fontSize": 'inherit',
          fontSize: "inherit",
          minWidth: `calc(${message.length}ch - ${message.length}px)`,
        }}
        // fullWidth={true}
        autoFocus={true}
        ref={newRef}
        onClick={(e) => e.stopPropagation()}
        onBlur={(e) => handleDialogueChange(e.target.value)}
        value={message}
        onChange={(e) => handleChange(e)}
      />
    );
  }

  const handleDialogueChange = (message) => {
    if (actualCharacterDialogue !== undefined) {
      // I want the format to stay compact so if Elements do not have "dialogue" I do not want a record
      // so I need this to work like
      // if characterDialogue exists do this -----------v
      // console.log("actualCharacterDialogue !== undefined");
      setDialogue(
        dialogue.map((d) =>
          (d.element === element && d.page === page)
            ? { ...d, message: message }
            : d
        ),
      );
    } else {
      // if characterDialogue does not exists
      // On Click create a record and maybe we need to add state to this
      // On change edit the message key
      // console.log("actualCharacterDialogue === undefined");
      setDialogue([...dialogue, {
        element: element,
        message: message,
        page: page,
      }]);
    }
  };

  const handleOnCharacterFocus = () => {
    if (characterFocus !== characterFocusId) {
      setCharacterFocus(characterFocusId);
    }
  };
  
  let backgroundStyling = stageDirectionsBackgroundStyling(
    page, stageDirections
  );
  return (
    <div
      key={characterFocusId}
      style={{ position: "relative"}}
      className={rowStyle}
      onClick={(e) => {
        handleOnCharacterFocus();
        e.stopPropagation();
      }}
    >

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          borderRadius: "inherit",
          zIndex: "0",
          background: backgroundStyling,
        }}
      />
      {(characterFocus === characterFocusId)
        ? (
          <StaffInput
            onClick={(e) => {
              e.stopPropagation();
              // console.log("Inside Click");
            }}
            // debounceTimeout={500}
            // handleDebounce={handleDialogueChange}
            element={element}
            page={page}
          />
        )
        : (
          actualCharacterDialogue === undefined ||
            actualCharacterDialogue === ""
            ? "\u00A0"
            : actualCharacterDialogue
        )}
    </div>
  );
}

export default DialogueInput;
