import * as React from "react";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ListItemText, ThemeProvider } from "@mui/material";

export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { menuProps } = props;

  const theme = createTheme({
    palette: {
      ochre: {
        main: "#c8c8c8",
        light: "#f8f8f8",
        dark: "#979798",
        contrastText: "#000",
      },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: "#d1eaff",
              boxShadow: "none",
            },
            backgroundColor: "#fff",
            borderRadius: menuProps.borderStyling,
            border: "1px solid transparent",
            fontFamily: "Google Sans, sans-serif",
            letterSpacing: "1px",
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            boxShadow: "none",
            textTransform: "none",
            margin: "0 0 0 0",
            color: "#000",
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            paddingLeft: "24px",
            paddingRight: "48px",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
        <Button
          sx={{my:0, py:0}}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="ochre"
        >
          {menuProps.menuName}
        </Button>
        <MenuList
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {menuProps.menuOptions.map((option) => (
            <MenuItem
              key={option.displayName}
            >
              <ListItemText
                onClick={(e) => {
                  option.action(option.tabIndex);
                  handleClose(e);
                }}
              >
                {option.displayName}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
    </ThemeProvider>
  );
}
