import React from "react";
import { stageDirectionsBackgroundStyling } from "../Common/TransitionStyling";
// import TransitionStaffLine from "./TransitionStaffLine.jsx";
function PresentationDialogue(
  {
    element,
    sectionName,
    dialogue,
    page,
    rowStyle,
    highlighted,
    setShowStageDirections,
    setStageDirections,
    stageDirections,
    showStageDirections,
  },
) {
  const [stageDirectionAction, setStageDirectionAction] = React.useState(null);

  let backgroundStyling = stageDirectionsBackgroundStyling(
    page,
    stageDirections,
  );
  const actualCharacterDialogue = dialogue.find((
    d,
  ) => (d.element === element && d.page === page))?.message;
  const characterFocusId = sectionName !== undefined
    ? `${element}-${sectionName}`
    : `${element}`;

  React.useEffect(() => {
    setStageDirectionAction(null);
  }, [showStageDirections]);
  return (
    <>
      <div
        // key={characterFocusId}
        style={{
          position: "relative",
          backgroundColor: highlighted ? "#fef7c3" : "#f0f0f0",
        }}
        className={rowStyle}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            borderRadius: "inherit",
            zIndex: "0",
            background: backgroundStyling,
          }}
        />
        {(actualCharacterDialogue === undefined ||
          actualCharacterDialogue === "")
          ? "\u00A0"
          : actualCharacterDialogue}
      </div>
    </>
  );
}

export default PresentationDialogue;
